import React, { useContext, useEffect } from "react";
import LoginScreen from "../../assets/login/memo_login.png";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import ProgressModal from "../common/ProgressModal";
import ErrorModal from "../common/ErrorModal";
import axios from "axios";

import AuthContext from "../../context/AuthContext";
import { useData } from "../../context/DataContext";

function Login() {
    const loginRef = useRef();
    let navigate = useNavigate();

    const { token, setToken, user, setUser, authError, setAuthError } = useContext(AuthContext);
    const { loadingStatus, setLoadingStatus } = useData();

    const {
        handleSubmit,
        control,
        trigger,
        register,
        formState: { errors },
    } = useForm();

    const submitHandler = async (data) => {
        setLoadingStatus(true);
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/auth/knock-knock/`, {
                email: data.email,
                password: data.password
            }).then((response) => {
                localStorage.setItem('Token', response.data.access);
                localStorage.setItem('User', response.data.user_full_name);
                setToken(response.data.access)
                setUser(response.data)
                navigate("/")
                setLoadingStatus(false);
            }).catch((err) => {
                setAuthError(err.response.data.message)
                setLoadingStatus(false);
            })

        } catch (error) {
            alert(error)
            setLoadingStatus(false);
        }
    };

    const goToRegister = () => {
        let path = "/register";
        navigate(path);
    }

    useEffect(() => {
        loginRef.current.scrollIntoView('smooth')
    }, [])



    return (
        <div className="login-section" ref={loginRef}>
            {loadingStatus && <ProgressModal uploading={loadingStatus} />}
            {authError !== "" &&
                <ErrorModal message={authError} setErrors={setAuthError} />
            }
            <img className="w-full object-cover h-[60vh]" src={LoginScreen} alt="Image" />
            <div className="login-card w-[85%] mt-[30px] mb-[30px] p-[20px] 2xl:w-[40%] lg:w-[50%] md:w-[60%] sm:w-[65%] xl:p-[50px] lg:p-[40px] md:p-[30px] sm:p-[25px] m-auto items-center flex flex-col bg-loginform xl:translate-y-[-350px] lg:translate-y-[-200px] md:translate-y-[-100px]  sm:translate-y-[-80px]">
                <h3 className="login-card-header text-center text-[20px] py-[10px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] font-bold xl:py-[50px] lg:py-[40px] md:py-[20px] sm:py-[20px] ">Log into your account</h3>
                <form onSubmit={handleSubmit(submitHandler)} className="w-[80%]">
                    <div className="form-group text-center pt-[10px] xl:pt-[50px] lg:pt-[30px] md:pt-[20px] sm:pt-[15px] mb-[1rem] flex flex-col">
                        <label htmlFor="email" className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.3rem]">Email</label>
                        <input
                            id="email"
                            name="email"
                            type='email'
                            required={true}
                            {...register("email", {
                                required: "Email is Required!!!",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                }
                            })}
                            error={Boolean(errors.email)}
                            onKeyUp={() => { trigger("email") }}

                            className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[.75rem] sm:text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
                        />
                        {errors.email && (
                            <span className="text-[13px] text-[#E94B45] text-left pt-[5px]">{errors.email.message}</span>
                        )}
                    </div>

                    <div className="form-group text-center pt-[10px] xl:pt-[30px] lg:pt-[25px]  md:pt-[15px] sm:pt-[10px] mb-[1rem] flex flex-col">
                        <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.5rem]">Password</label>
                        <input className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[1.5rem] sm:text-[2rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
                            name='password'
                            id="password"
                            type='password'
                            autoComplete='off'
                            required={true}
                            {...register("password", {
                                required: "You must specify a password",
                                pattern: {
                                    value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){    1,})(?!.*\s).{8,}$',
                                    message: "Password should contain at least one number and one    special character"
                                },
                                minLength: {
                                    value: 8,
                                    message: "Password must be more than 8 characters"
                                },
                                maxLength: {
                                    value: 20,
                                    message: "Password must be less than 20 characters"
                                },
                            })}
                            onKeyUp={() => { trigger("password") }}
                            error={Boolean(errors.password)}
                        />
                        {errors.password && (
                            <span className="text-[13px] text-[#E94B45] text-left pt-[5px]">{errors.password.message}</span>
                        )}
                    </div>

                    <div className="form-group text-center pt-[10px] xl:pt-[50px] lg:pt-[30px]  md:pt-[20px] sm:pt-[15px] mb-[1rem] flex flex-col">
                        <button className="bg-[#E94B45] w-full h-[35px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-white font-bold md:text-[20px] sm:text-[15] " type="submit">Log in</button>
                    </div>

                </form>

                <div className="items-left">
                    <button className="text-[14px] sm:text-[16px]" onClick={goToRegister}>Register</button>
                </div>
            </div>
        </div>

    );


}

export default Login;
