import React from 'react'
import { useLocation } from 'react-router-dom'

function Option2({ image, onLoad, index }) {

  const location = useLocation();
  const default_style = {
    width: '60px',
    height: '55px',
  }

  const fill_style = {
    width: '80%',
    height: '80%',
  }


  if (index !== 1) {
    return (
      <img
        src={image.cropped_src ? image.cropped_src : image.src}
        className="object-contain"
        style={image.fill === true ? fill_style : default_style}
        alt=''
        onLoad={onLoad}
      />
    )
  }

  if (location.pathname.includes("basket") && index === 1) {
    return (
      <div className='flex flex-col justify-between w-[230px] h-full drop-shadow-lg scale-[0.6]'
        style={{
          color: image.text_color,
          backgroundColor: image.background_color
        }}
      >
        <img
          src={image.cropped_src ? image.cropped_src : image.src}
          className="object-contain mb-1"
          alt=''
        />
        <p className='text-[10px] text-left px-1'>{image.title}</p>
        <p className='text-[8px] text-left font-light px-1 mb-1'>{image.subtitle}</p>
      </div>
    )
  }


  return (
    <div className='flex flex-col justify-between h-full'
      style={{
        color: image.text_color,
        backgroundColor: image.background_color
      }}
    >
      <img
        src={image.cropped_src ? image.cropped_src : image.src}
        className="object-contain w-full h-[55px] mb-1"
        alt=''
        onLoad={onLoad}
      />
      <p className='text-[5px] text-left px-1'>{image.title}</p>
      <p className='text-[3px] text-left font-light px-1 mb-1'>{image.subtitle}</p>
    </div>
  )

}

export default Option2