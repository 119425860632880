import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import FullImageCropper from '../options/cropper-options/FullImageCropper';
import GrabHand from "../../../assets/hand.svg";

import Shadow from "../../../assets/album-pages/shadow.png";


function Indicies({
    selected_image_index,
    setSwiperRef,
    items,
    setFit,
    setFill,
    rotateImage,
    image_ref,
    setCustomText,
    setFitFull,
    setFitMedium,
    setFitSmall,
    setFitSmallMessage,
    setFillFullImage,
    setFillMedium,
    setFillSmall,
    setFillSmallMessage,
    cropper_ref

}) {

    const history = useNavigate();


    const [nextDisabled, setNextDisabled] = useState(false);
    const [prevDisabled, setPrevDisabled] = useState(false);

    const [helpBtn, setHelpBtn] = useState(() => {
        const storedValue = sessionStorage.getItem('prompt_help');
        return storedValue ? JSON.parse(storedValue) : true;
    });




    const { indices } = useParams();
    const idx = indices.split('-');
    const [indexList, setIndexList] = useState({
        start: Number(idx[0]),
        end: Number(idx[1]) + 1
    });

    useEffect(() => {
        setIndexList({
            start: Number(idx[0]),
            end: Number(idx[1])
        })
    }, [indices])





    const handleLeftClick = useCallback(() => {

        let prev_start = 0;
        let prev_end = 0;
        if (selected_image_index === 5) {
            prev_start = selected_image_index - 1;
            prev_end = prev_start + 1;
        } else {
            prev_start = selected_image_index - 2;
            prev_end = prev_start + 1;
        }

        sessionStorage.setItem('selected_image_index', String(prev_start));

        history(`/album/edit/${prev_start}-${prev_end}`)

    }, [selected_image_index, history]);


    const handleRightClick = useCallback(() => {
        let next_start = 0;

        if (selected_image_index === 5) {
            next_start = selected_image_index + 1;
        } else {
            next_start = selected_image_index + 2;
        }

        let next_end = next_start + 1;

        sessionStorage.setItem('selected_image_index', String(next_start))

        history(`/album/edit/${next_start}-${next_end}`);

        setPrevDisabled(false)

    }, [selected_image_index, history]);


    useEffect(() => {


        if (selected_image_index % 2 !== 0) {
            history(`/album/edit/${selected_image_index - 1}-${selected_image_index}`)
        }

        if (indexList.start === 4) {
            setPrevDisabled(true);
        } else {
            setPrevDisabled(false)
        }


        if (items[indexList.start]?.src.includes("firebase") && items[indexList.end]?.src === 'icon') {
            setNextDisabled(true);
        } else if (items[indexList.start]?.src === 'icon' && items[indexList.end]?.src.includes("firebase")) {
            setNextDisabled(true);
        } else if ((items[indexList.end + 1]?.src === 'icon' && items[indexList.end + 2]?.src === 'icon') || (items[indexList.end + 1]?.src === 'empty' && items[indexList.end + 2]?.src === 'empty')) {
            setNextDisabled(true);
        } else {
            setNextDisabled(false);
        }

    }, [items, indices, indexList])


    useEffect(() => {
        sessionStorage.setItem('prompt_help', JSON.stringify(helpBtn))
    }, [helpBtn])

    const handleHelp = () => {
        setHelpBtn(!helpBtn);
    }

    return (
        <>

            <div className='flex flex-row items-center justify-between'>
                <button onClick={handleLeftClick} disabled={prevDisabled}>
                    <p className={`custom-arrow custom-arrow-left ${prevDisabled ? 'border-gray-[500]' : 'border-black'}`}></p>
                </button>
                <p className="text-[18px] font-semibold">{selected_image_index - 4}</p>
            </div>


            <div className='grid grid-cols-2 items-center md:scale-[0.7] lg:scale-[0.8] 2xl:scale-[1]'>
                {items?.map((item, index) => (index >= indexList.start && index <= indexList.end &&
                    <div key={index} className="">
                        {item.src !== 'empty' && item.src !== 'icon' ?
                            <div
                                className='grid grid-cols-3 justify-items-center items-center gap-2 bg-white w-1/2 mx-auto p-3 mb-12'
                            >
                                {/* Buttons */}
                                <button id={index} onClick={(e) => setFit(e, index)}>
                                    <span
                                        className={`font-[500] ${item['fill'] === false ? 'text-red-500 font-[700]' : 'text-black'}`}>Fit</span>
                                </button>
                                <button id={index} onClick={(e) => setFill(e, index)}><span
                                    className={`font-[500] ${item['fill'] === true ? 'text-red-500 font-[700]' : 'text-black'}`}>Fill</span>
                                </button>
                                <button id={index} onClick={() => rotateImage(index)}><span
                                    className='font-[500]'>Rotate</span></button>
                            </div>
                            :

                            <div className={index % 2 === 0 ? '' : 'square_wrapper_right'}>
                            </div>
                        }
                        {item.src !== 'empty' && item.src !== 'icon' ?

                            <div className={index % 2 === 0 ? 'frontpage' : 'backpage'}>
                                <div className={index % 2 === 0 ? 'pageFoldLeft' : 'pageFoldRight'}>
                                    {/* Image */}
                                    {item['fill'] === false ?
                                        <div className='imagewrapper'>
                                            <img src={item.src} alt="album"
                                                className={`${item.padding_option === 0 ? 'album-image-full' : ''} ${item.padding_option === 1 ? 'album-image-medium' : ''} ${item.padding_option === 2 ? 'album-image-small' : ''} ${item.padding_option === 3 ? 'album-image-text' : ''} custom-rotate-${item.rotate}`}
                                                ref={image_ref}
                                                id={`image-` + index}
                                            />
                                        </div>
                                        :
                                        <div
                                            className={`${item.padding_option !== 3 ? 'relative h-[550px] w-[550px]' : 'translate-y-12'}  object-cover`}>
                                            {helpBtn ?
                                                <div className='bg-secondary w-full h-full absolute z-[1000] justify-center flex flex-col items-center'>

                                                    <img src={GrabHand} className="w-[150px] h-[150px] mb-12" />
                                                    <div className='flex flex-row justify-between text-white items-center gap-6'>
                                                        <p className='font-[400]'>Hold-swipe photo to set framing</p>
                                                        <button onClick={handleHelp} className="border border-white rounded-full px-8 py-4 font-[600] hover:bg-white hover:text-red-500 hover:transition-all">I understand</button>

                                                    </div>
                                                </div>
                                            : null}
                                            <FullImageCropper image={item.src}
                                                image_current_option={item.padding_option}
                                                image_arr={item} id={index}
                                                cropper_ref={cropper_ref} />
                                        </div>
                                    }
                                    {item.padding_option === 3 ?
                                        <div className="description-wrapper translate-y-24">
                                            <textarea
                                                className="relative outline-none w-1/2 p-5 mx-auto text-center h-auto resize-none"
                                                placeholder="Please add text here"
                                                maxLength={40}
                                                value={item.description}
                                                onChange={(e) => setCustomText(e, item)}></textarea>
                                        </div>
                                        : null}

                                </div>

                            </div>
                            :
                            <div className={index % 2 === 0 ? 'frontpage -translate-y-1' : 'backpage -translate-y-1'}>
                                <div className={index % 2 === 0 ? 'pageFoldLeft' : 'pageFoldRight'}>
                                    <div className='imagewrapper bg-white cursor-not-allowed'>
                                        <img src={item.src} alt="album"
                                            className="album-image-full"
                                        // ref={image_ref}
                                        // id={`image-` + index}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {item.fill === true && help && <button onClick={handleMemo}>Understand</button>} */}
                        {item.src !== 'empty' && item.src !== 'icon' ?
                            <div
                                // className={index % 2 === 0 ? 'square_wrapper_right mb-5' : 'square_wrapper_left mb-5'}
                                className='grid grid-cols-4 justify-items-center items-center bg-white w-[60%] mx-auto py-3 mt-12'

                            >
                                {/* Options */}
                                {item['fill'] === false ?
                                    <>

                                        <button onClick={(e) => setFitFull(index, e)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <rect className={item.padding_option === 0 ? 'fill-red-500' : null}
                                                    id="Rectangle_29" data-name="Rectangle 29" width="22"
                                                    height="22" fill="#89888d" />
                                            </svg>
                                        </button>
                                        <button onClick={() => setFitMedium(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <g id="Group_60" data-name="Group 60"
                                                    transform="translate(-594 -843)">
                                                    <g id="Path_13" data-name="Path 13"
                                                        transform="translate(594 843)" fill="none">
                                                        <path d="M0,0H22V22H0Z" stroke="none" />
                                                        <path
                                                            className={item.padding_option === 1 ? 'fill-red-500' : null}
                                                            d="M 1 1 L 1 21 L 21 21 L 21 1 L 1 1 M 0 0 L 22 0 L 22 22 L 0 22 L 0 0 Z"
                                                            stroke="none" fill="#89888d" />
                                                    </g>
                                                    <rect className={item.padding_option === 1 ? 'fill-red-500' : null}
                                                        id="Rectangle_31" data-name="Rectangle 31" width="14"
                                                        height="14" transform="translate(598 847)"
                                                        fill="#89888d" />
                                                </g>
                                            </svg>
                                        </button>
                                        <button onClick={() => setFitSmall(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <g id="Group_61" data-name="Group 61"
                                                    transform="translate(-649 -843)">
                                                    <g id="Rectangle_32" data-name="Rectangle 32"
                                                        transform="translate(649 843)"
                                                        className={item['padding_option'] === 2 ? 'stroke-red-500' : null}
                                                        fill="#fff" stroke="#89888d" strokeWidth="1">
                                                        <rect width="22" height="22" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="21" height="21"
                                                            fill="none" />
                                                    </g>
                                                    <rect
                                                        className={item['padding_option'] === 2 ? 'fill-red-500' : null}
                                                        id="Rectangle_33" data-name="Rectangle 33" width="10"
                                                        height="10" transform="translate(655 849)"
                                                        fill="#89888d" />
                                                </g>
                                            </svg>
                                        </button>
                                        <button onClick={() => setFitSmallMessage(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <g id="Group_62" data-name="Group 62"
                                                    transform="translate(-702 -843)">
                                                    <g id="Rectangle_35" data-name="Rectangle 35"
                                                        transform="translate(702 843)" fill="#fff"
                                                        className={item['padding_option'] === 3 ? 'stroke-red-500' : null}
                                                        stroke="#89888d" strokeWidth="1">
                                                        <rect width="22" height="22" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="21" height="21"
                                                            fill="none" />
                                                    </g>
                                                    <rect id="Rectangle_34" data-name="Rectangle 34" width="14"
                                                        height="11" transform="translate(706 846)"
                                                        fill="#89888d"
                                                        className={item['padding_option'] === 3 ? 'fill-red-500' : null} />
                                                    <g id="Rectangle_36" data-name="Rectangle 36"
                                                        transform="translate(708 859)"
                                                        className={item['padding_option'] === 3 ? 'stroke-red-500 fill-red-500' : null}
                                                        fill="#707070" stroke="#707070" strokeWidth="1">
                                                        <rect width="10" height="1" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="9" fill="none" />
                                                    </g>
                                                    <g id="Rectangle_37" data-name="Rectangle 37"
                                                        transform="translate(708 861)"
                                                        className={item['padding_option'] === 3 ? 'stroke-red-500 fill-red-500' : null}
                                                        fill="#707070" stroke="#707070" strokeWidth="1">
                                                        <rect width="10" height="1" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="9" fill="none" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </> :
                                    <>
                                        <button onClick={(e) => setFillFullImage(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <rect className={item['padding_option'] === 0 ? 'fill-red-500' : null}
                                                    id="Rectangle_29" data-name="Rectangle 29" width="22"
                                                    height="22" fill="#89888d" />
                                            </svg>
                                        </button>
                                        <button onClick={(e) => setFillMedium(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <g id="Group_60" data-name="Group 60"
                                                    transform="translate(-594 -843)">
                                                    <g id="Path_13" data-name="Path 13"
                                                        transform="translate(594 843)" fill="none">
                                                        <path d="M0,0H22V22H0Z" stroke="none" />
                                                        <path
                                                            className={item.padding_option === 1 ? 'fill-red-500' : null}
                                                            d="M 1 1 L 1 21 L 21 21 L 21 1 L 1 1 M 0 0 L 22 0 L 22 22 L 0 22 L 0 0 Z"
                                                            stroke="none" fill="#89888d" />
                                                    </g>
                                                    <rect className={item.padding_option === 1 ? 'fill-red-500' : null}
                                                        id="Rectangle_31" data-name="Rectangle 31" width="14"
                                                        height="14" transform="translate(598 847)"
                                                        fill="#89888d" />
                                                </g>
                                            </svg>
                                        </button>
                                        <button onClick={(e) => setFillSmall(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <g id="Group_61" data-name="Group 61"
                                                    transform="translate(-649 -843)">
                                                    <g id="Rectangle_32" data-name="Rectangle 32"
                                                        transform="translate(649 843)"
                                                        className={item['padding_option'] === 2 ? 'stroke-red-500' : null}
                                                        fill="#fff" stroke="#89888d" strokeWidth="1">
                                                        <rect width="22" height="22" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="21" height="21"
                                                            fill="none" />
                                                    </g>
                                                    <rect
                                                        className={item['padding_option'] === 2 ? 'fill-red-500' : null}
                                                        id="Rectangle_33" data-name="Rectangle 33" width="10"
                                                        height="10" transform="translate(655 849)"
                                                        fill="#89888d" />
                                                </g>
                                            </svg>
                                        </button>
                                        <button onClick={() => setFillSmallMessage(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                viewBox="0 0 22 22">
                                                <g id="Group_62" data-name="Group 62"
                                                    transform="translate(-702 -843)">
                                                    <g id="Rectangle_35" data-name="Rectangle 35"
                                                        transform="translate(702 843)" fill="#fff"
                                                        className={item['padding_option'] === 3 ? 'stroke-red-500' : null}
                                                        stroke="#89888d" strokeWidth="1">
                                                        <rect width="22" height="22" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="21" height="21"
                                                            fill="none" />
                                                    </g>
                                                    <rect id="Rectangle_34" data-name="Rectangle 34" width="14"
                                                        height="11" transform="translate(706 846)"
                                                        fill="#89888d"
                                                        className={item['padding_option'] === 3 ? 'fill-red-500' : null} />
                                                    <g id="Rectangle_36" data-name="Rectangle 36"
                                                        transform="translate(708 859)"
                                                        className={item['padding_option'] === 3 ? 'stroke-red-500 fill-red-500' : null}
                                                        fill="#707070" stroke="#707070" strokeWidth="1">
                                                        <rect width="10" height="1" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="9" fill="none" />
                                                    </g>
                                                    <g id="Rectangle_37" data-name="Rectangle 37"
                                                        transform="translate(708 861)"
                                                        className={item['padding_option'] === 3 ? 'stroke-red-500 fill-red-500' : null}
                                                        fill="#707070" stroke="#707070" strokeWidth="1">
                                                        <rect width="10" height="1" stroke="none" />
                                                        <rect x="0.5" y="0.5" width="9" fill="none" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>

                                    </>


                                }

                            </div>
                            :
                            <div>

                            </div>
                        }
                    </div>
                ))
                }
                <img src={Shadow} alt="middle image" className='middle-image-cover' />

            </div>


            <div className='flex flex-row items-center justify-between'>
                <p className="text-[18px] font-semibold">{(selected_image_index - 4) + 1}</p>
                <button onClick={handleRightClick} disabled={nextDisabled}>
                    <p className={`custom-arrow custom-arrow-right ${nextDisabled ? ' border-gray-[500]' : 'border-black'}`}></p>
                </button>
            </div>

        </>
    )

}

export default Indicies

