import { useState } from "react";
import CloseBtn from "../../../assets/cover/Close_S.svg";
import MemopixLogo from "../../../assets/logo.png";

import { db } from "../../../firebase";
import { ref, get, update } from "firebase/database";

const CoverImagePicker = ({ images, set_toggle, session_id }) => {
    const [modal, setModal] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    let current_album_id = sessionStorage.getItem('album_id');

    const toggleModal = () => {
        setModal(!modal);
        set_toggle(false);
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    const getImageSrc = (image) => {
        setSelectedImage(image);

        images[1].src = image.src;
    }

    const handleCoverImageUpdate = () => {
        update(ref(db, `${session_id}/${parseInt(current_album_id)}`), {
            'images': images,
            'album_id': parseInt(current_album_id),
            'copies': 1
        })
        set_toggle(false);
    }

    return (
        <>
            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="data-modal-content w-[80%]">
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                                <img src={MemopixLogo} className="w-[200px]" />
                                <img src={CloseBtn} onClick={toggleModal} className='w-[45px] cursor-pointer' />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-12">
                            <p className="text-center text-[23px] font-semibold mt-12">Here you can pick any of the images you uploaded.</p>
                            <div className="grid grid-cols-6 gap-6 justify-items-center">
                                {images.map((item, index) => {
                                    return (
                                        item.src !== 'empty' && item.src !== 'cover' && item.src !== "icon" && item.src !== 'back' && item.src !== 'delete' && index > 1 &&
                                        <img src={item.src} key={index} className={`cursor-pointer w-[230px] h-[230px] object-contain ${item === selectedImage ? 'outline outline-[#ED4A43] outline-4' : ''}`} onClick={() => getImageSrc(item)} />
                                    )
                                })}
                            </div>
                            <div className="mx-auto">
                                {selectedImage &&
                                    <button
                                        onClick={() => handleCoverImageUpdate()}
                                        className='text-center bg-[#ED4A43] text-white w-[300px] h-[60px] uppercase font-bold text-[17px]'
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CoverImagePicker;
