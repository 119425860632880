import React from 'react'
import { useLocation } from 'react-router-dom'


function Option3({ image, onLoad, index }) {

  const location = useLocation();

  const default_style = {
    width: '50px',
    height: '50px',
  }


  const fill_style = {
    width: '60%',
    height: '60%',
  }

  if (index !== 1) {
    return (
      <img
        src={image.cropped_src ? image.cropped_src : image.src}
        className="object-contain"
        style={image.fill === true ? fill_style : default_style}
        alt=''
        onLoad={onLoad}
      />
    )
  }

  if (location.pathname.includes("basket") && index === 1) {
    return (
      <div className='flex flex-col justify-between w-[230px] h-full drop-shadow-lg scale-[0.8]'
        style={{
          color: image.text_color,
          backgroundColor: image.background_color
        }}
      >
        <p className='text-[10px] text-left px-1'>{image.title}</p>
        <p className='text-[8px] text-left font-light px-1 mb-1'>{image.subtitle}</p>
        <img
          src={image.cropped_src ? image.cropped_src : image.src}
          className="object-contain"
          alt=''
        />

      </div>
    )
  }

  return (
    <div className='flex flex-col'
      style={{
        color: image.text_color,
        backgroundColor: image.background_color
      }}
    >

      <p className='text-[5px] text-left px-1 pt-1'>{image.title}</p>
      <p className='text-[3px] text-left font-light pb-1 px-1'>{image.subtitle}</p>
      <img
        src={image.cropped_src ? image.cropped_src : image.src}
        className="object-contain w-full h-[60%]"
        alt=''
        onLoad={onLoad}
      />
    </div>
  )

}

export default Option3