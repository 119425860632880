import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SiteLogo from "../../assets/logo.svg";
import BasketIcon from "../../assets/svgs/basket.svg";
import AuthContext from '../../context/AuthContext';

function Navbar() {

  const location = useLocation();

  const { user } = useContext(AuthContext);

  let active = false;

  if (location.pathname.includes("album") || location.pathname.includes('drag')) {
    active = true
  }

  let not_allowed = false;

  if (location.pathname.includes("edit")) {
    not_allowed = true;
  }




  return (
    <nav className={`bg-rgba_opacity z-10 ${active ? "" : "absolute"} w-full h-[116px] flex items-center ${not_allowed ? 'hidden' : ''}`}>
      <div className='w-[95%] p-5 mx-auto grid grid-cols-2 justify-items-stretch items-center'>
        <div className='flex flex-row items-center'>
          <Link to="/" className='xs:w-[100px] sm:w-[100px] lg:w-[120px]'><img src={SiteLogo} alt="site-logo" /></Link>
        </div>
        <div className='flex flex-row justify-end gap-12 items-center'>
          {user.user_full_name ? <p>Hello, {user.user_full_name}</p> :
            <Link to="/login" className='font-[700] xs:text-[14px] sm:text-[14px] lg:text-[16px]'>Login</Link>
          }

          <Link to="/basket"> <img src={BasketIcon} alt="basket-icon" className='xs:w-[20px] sm:w-[20px] lg:w-[30px] font-[700]' /> </Link>
        </div>
      </div>
    </nav>

  )
}

export default Navbar