import React from 'react'
import { useLocation } from 'react-router-dom'

function Option4({ image, onLoad, index }) {

  const location = useLocation();

  if (index !== 1) {
    return (
      <div className='flex flex-col justify-center items-center'>
        <img src={image.cropped_src ? image.cropped_src : image.src} className='w-[40px] h-[40px] object-contain mt-2' alt='' onLoad={onLoad} />
        {image.description ? <p className='text-[2px] font-light mb-1 mt-1 w-[70%] text-center'>{image.description}</p> : null}
      </div>
    )
  }

  if (location.pathname.includes("basket") && index === 1) {
    return (
      <div className='flex flex-col justify-between w-[230px] h-full drop-shadow-lg scale-[0.8]'
        style={{
          color: image.text_color,
          backgroundColor: image.background_color
        }}
      >
        <p className='text-[10px] text-center py-1 font-light'>{image.subtitle}</p>
        <img
          src={image.cropped_src ? image.cropped_src : image.src}
          className="object-contain"
          alt=''
        />
        <p className='text-[8px] text-center font-bold py-1'>{image.title}</p>


      </div>
    )
  }


  return (
    <div className='flex flex-col'
      style={{
        color: image?.text_color,
        backgroundColor: image?.background_color
      }}
    >

      <p className='text-[3px] text-center font-light mt-1'>{image.subtitle}</p>
      <img
        src={image.cropped_src ? image.cropped_src : image.src}
        className="object-contain w-full h-[50px] mx-auto"
        alt=''
        onLoad={onLoad}
      />
      <p className='text-[5px] text-center mt-1'>{image.title}</p>

    </div>
  )

}

export default Option4