import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "./index.css";

import Carousel from './carousel/Carousel';
import useFetch from '../../hooks/useFetch';

function Home() {

    const album_list_section = useRef();
    const history = useNavigate();

    // Gets all types of albums
    
    const { data } = useFetch(`${process.env.REACT_APP_BACKEND_API}/album/type/`, []);
 
    const redirectToAlbumPreview = (album_id) => {
        history(`/album/${album_id}`);
    }

    return (
        <>
            <Carousel reference={album_list_section} />
            <div className='mt-5 mb-5'>
                <div className='xs:w-[90%] sm:w-[90%] lg:w-[50%] mx-auto my-10'>
                    <h3 className='font-bold text-center text-[25px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at ornare felis. Pellentesque molestie. Ut porta mauris eu metus tincidunt, eu pharetra augue fringilla. Aliquam lacinia mi vestibulum neque fringilla accumsan.</h3>
                </div>
                <div ref={album_list_section} className='grid xs:grid-rows-1 sm:grid-rows-1 lg:grid-cols-3 mx-auto xs:w-[90%] sm:w-[90%] lg:w-[60%] gap-6 justify-items-stretch'>
                    {data?.map((item) => (
                        <div key={item.name} className='flex flex-col cursor-pointer mx-auto' onClick={() => redirectToAlbumPreview(item.id)}>
                            <img src={item.image} className="w-[380px] h-[330px] object-cover" alt={item.name} />
                            <div className='text-left'>
                                <p className='font-bold mt-5 mb-2 text-[20px]'>{item.name}</p>
                                <p className='font-regular text-[15px]'>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>

    )
}

export default Home