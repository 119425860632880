import React, { useEffect } from "react";
import PhotoAlbum from "../../assets/photoalbum.png";
import Edit from "../../assets/svgs/editbasket.svg";
import Delete from "../../assets/svgs/trashbasket.svg";
import { useState } from "react";
import { db } from "../../firebase";
import { ref, get, remove } from "firebase/database";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useData } from '../../context/DataContext';
import ProgressModal from "../common/ProgressModal";
import EmptyBasket from "./EmptyBasket";
import Option2 from "../drag-n-drop/previews/Option2";
import Option3 from "../drag-n-drop/previews/Option3";
import Option4 from "../drag-n-drop/previews/Option4";
import Option5 from "../drag-n-drop/previews/Option5";
import Option6 from "../drag-n-drop/previews/Option6";
import Option7 from "../drag-n-drop/previews/Option7";
import Option8 from "../drag-n-drop/previews/Option8";
import Option9 from "../drag-n-drop/previews/Option9";
import Option10 from "../drag-n-drop/previews/Option10";



export default function Basket() {

  const history = useNavigate();
  let current_album_id = sessionStorage.getItem("album_id");
  const session_id = sessionStorage.getItem("sessionId");
  var default_price = sessionStorage.getItem("album_default_price");
  var name = sessionStorage.getItem("album_name");

  const [album, setAlbum] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(default_price);
  const [albumCoverImage, setAlbumCoverImage] = useState({
    src: undefined,
    option: undefined,
    title: "",
    subtitle: "",
    background_color: "",
    text_color: ""
  });

  const { loadingStatus, setLoadingStatus, setAlbumImages, setCoverTitle, setCoverSubTitle } = useData();


  useEffect(() => {
    const storedQuantity = sessionStorage.getItem("quantity");
    if (storedQuantity) {
      setQuantity(parseInt(storedQuantity));
    }

  }, []);


  useEffect(() => {
    setLoadingStatus(true);
    get(ref(db, `${session_id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        let firebase_album_id = snapshot?.val()[current_album_id].album_id;
        if (firebase_album_id === parseInt(current_album_id)) {
          var firebase_album = snapshot.val()[current_album_id];
          var firebase_album_cover = snapshot.val()[current_album_id].images[1];


          setAlbum(firebase_album);


          if (firebase_album_cover.cropped_src) {
            setAlbumCoverImage({
              src: firebase_album_cover.cropped_src,
              option: firebase_album_cover.option,
              title: firebase_album_cover.title,
              subtitle: firebase_album_cover.subtitle,
              background_color: firebase_album_cover.background_color,
              text_color: firebase_album_cover.text_color
            })
          }
          else {
            setAlbumCoverImage({
              src: firebase_album_cover.src,
              option: firebase_album_cover.option,
              title: firebase_album_cover.title,
              subtitle: firebase_album_cover.subtitle,
              background_color: firebase_album_cover.background_color,
              text_color: firebase_album_cover.text_color
            })
          }
          setLoadingStatus(false);


        } else {
          setLoadingStatus(false);

        }
      }
    });

  }, []);



  const deleteAlbum = (id) => {

    if (window.confirm("Are you sure you want to delete this album? If so every change will be lost.")) {
      remove(ref(db, `${session_id}/${id}`))
      setAlbum({});
      setAlbumImages([])
    } else {
      return
    }


  };

  const editAlbum = () => {
    history("/drag-n-drop");
  };

  const toShopping = () => {
    history("/drag-n-drop");
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    setTotalPrice(default_price * (quantity + 1));
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setTotalPrice(default_price * (quantity - 1));
    }
  };

  const handleChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    setQuantity(newQuantity);
    setTotalPrice(default_price * newQuantity);
  };


  function emptyAlbumData() {
    setAlbumImages([]);
    setCoverTitle("");
    setCoverSubTitle("");
  }

  const buyAlbum = async () => {
    setLoadingStatus(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_API}/album/upload/`, {
          images: {
            album_id: album.album_id,
            copies: quantity,
            images: album.images,
          },
          code: session_id,
          price: totalPrice
        })
        .then(() => {
          alert("Album Created Successfully!");
          emptyAlbumData();
          setLoadingStatus(false);
          history('/');
          sessionStorage.clear();
          localStorage.clear();
        })
        .catch((e) => {
          alert("Something went wrong!");
          setLoadingStatus(false);
        });
    } catch (e) {
      setLoadingStatus(false);
      console.log(e);
    }
  };

  if (Object.keys(album).length === 0) {
    return <EmptyBasket loadingStatus={loadingStatus} />
  }


  return (
    <div className="basket-section w-full h-full">

      {loadingStatus &&
        <ProgressModal uploading={loadingStatus} />
      }
      <div className="basket-header text-center pt-[200px] pb-[20px]">
        <span className="text-[28px] xl:text-[35px] lg:text-[33px] md:text-[30px] sm:text-[28px] font-bold">
          Basket
        </span>
      </div>
      <div>
        <div className="basket-container text-center w-[70%] xl:w-[70%] lg:w-[70%] md:w-[80%] sm:w-[90%] m-auto ">
          <div className="basket-item w-full flex flex-row flex-wrap  border-b-2 py-4">
            <div className="sl:block lg:block md:block sm:block photo-album w-1/5 grid items-center justify-center">
              {albumCoverImage.option === 1 && <Option2 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 2 && <Option3 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 3 && <Option4 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 4 && <Option5 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 5 && <Option6 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 6 && <Option7 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 7 && <Option8 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 8 && <Option9 image={albumCoverImage} index={1} />}
              {albumCoverImage.option === 9 && <Option10 image={albumCoverImage} index={1} />}
              {/* <img src={albumCoverImage.src} className="w-[140px] h-[140px] object-contain bg-white p-2 drop-shadow-md" /> */}
            </div>
            <div className="album-name w-full xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/5 xl:text-[20px] lg:text-[18px] md:text-[18px] sm:text-[16px] font-bold grid items-center text-left xl:text-center lg:text-center md:text-center sm:text-center">
              {name}
            </div>
            <div className="pt-[20px] xl:pt-[0px] lg:pt-[0px] md:pt-[0px] sm:pt-[0px] w-1/3 xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/5 items-center flex flex-row space-x-2 xl:space-x-8 lg:space-x-4 md:space-x-4  sm:space-x-2 justify-center">
              <div className="album-edit ">
                <button onClick={() => editAlbum()}>
                  <img
                    src={Edit}
                    className=" w-[18px] h-[15px] xl:w-[27px] lg:w-[27px] md:w-[20px] sm:w-[20px] xl:h-[20px] lg:h-[20px] md:h-[17px]  sm:h-[17px] "
                  />
                </button>
              </div>
              <div className="album-delete">
                <button onClick={() => deleteAlbum(album.album_id)}>
                  <img
                    src={Delete}
                    className="w-[18px] xl:w-[27px] lg:w-[27px] md:w-[20px] sm:w-[20px]"
                  />
                </button>
              </div>
            </div>
            <div className="album-quantity pt-[20px] xl:pt-[0px] lg:pt-[0px] md:pt-[0px] sm:pt-[0px] w-1/3 xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/5 grid items-center">
              <div className="quantity-counter h-full xl:h-1/3 lg:h-1/3 md:h-1/3 sm:h-1/3 items-center flex bg-[#FFFFFF]">
                <div className="w-1/4 border-r-2 border-[#595959]">
                  <button onClick={handleDecrement}>-</button>
                </div>
                <input
                  className="w-2/4 text-[16px] xl:text-[20px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[#E94B45] font-bold text-center"
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleChange}
                />
                <div className="w-1/4 border-l-2 border-[#595959]">
                  <button onClick={handleIncrement}>+</button>
                </div>
              </div>
            </div>
            <div className="pt-[20px] xl:pt-[0px] lg:pt-[0px] md:pt-[0px] sm:pt-[0px] w-1/3 xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/5 grid items-center text-[16px]  xl:text-[20px] lg:text-[18px] md:text-[18px] sm:text-[18px] font-bold text-[#595959]">
              {totalPrice} ALL
            </div>
          </div>
        </div>
        <div className="w-[80%] h-[80px] m-auto flex flex-row items-center justify-end space-x-4">
          <p className="xl:text-[20px] lg:text-[18px] text-[#595959] font-bold">
            Total :
          </p>
          <span className="xl:text-[20px] lg:text-[18px] font-bold pr-[10%]">
            {totalPrice} ALL
          </span>
        </div>
      </div>
      <div className="basket-buttons-section text-center w-[100%] xl:w-[50%] lg:w-[60%] md:w-[65%] sm:w-[75%] m-auto space-x-2 xl:space-x-4 lg:space-x-4 md:space-x-4 sm:space-x-4 pb-[100px]">
        <button
          onClick={() => toShopping()}
          className="w-[110px] xl:w-[200px] lg:w-[170px] md:w-[150px] sm:w-[140px] text-[11px] xl:text-[16px] lg:text-[16px] md:text-[15px] sm:text-[14px] text-white h-[40px] xl:h-[50px] lg:h-[50px] md:h-[50px] sm:h-[50px]  bg-[#BBB9B9] rounded-full font-bold hover:transition-all hover:opacity-90"
        >
          Back to shopping
        </button>
        <button onClick={buyAlbum} className="w-[130px] xl:w-[350px] lg:w-[330px]  md:w-[300px] sm:w-[290px] text-[11px] xl:text-[16px] lg:text-[16px] md:text-[15px] sm:text-[14px] text-white h-[40px] xl:h-[50px] lg:h-[50px] md:h-[50px] sm:h-[50px]  bg-[#E94B45] rounded-full hover:bg-white hover:text-[#ED4A43] hover:border hover:border-[#ED4A43] hover:transition-all">
          BUY
        </button>
      </div>
    </div>
  );
}
