import React from 'react';
import { useLocation } from 'react-router-dom';
import InstagramIcon from "../../assets/svgs/instagram.svg";
import FacebookIcon from "../../assets/svgs/facebook.svg";
import PinterestIcon from "../../assets/svgs/pinterest.svg";

function Footer() {
  const location = useLocation();

  let not_allowed = false;

  if (location.pathname.includes("edit") || location.pathname.includes("drag-n-drop")) {
    not_allowed = true;
  }

  return (
    <footer className={`bg-white ${not_allowed ? 'hidden' : ''}`}>
      <div className='grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-4 xs:w-[80%] sm:w-[80%] lg:w-[60%] mx-auto text-center pt-10 pb-5'>
        <div className='xs:my-6 sm:my-6 lg:my-0'>
          <h3 className='font-bold uppercase'>Store Information</h3>
          <div className='mt-3 text-center footer-links'>
            <p>Develop Europe AB,</p>
            <p>Forslov, Sweden</p>
            <a href="mailto:hello@gridcoding.com">hello@gridcoding.com</a>
          </div>
        </div>
        <div className='xs:my-6 sm:my-6 lg:my-0'>
          <h3 className='font-bold uppercase'>Products</h3>
          <div className='mt-3 text-center footer-links'>
            <p>Photo books</p>
            <p>Fine Arts Posters</p>
            <p>Collage Posters</p>
            <p>Story Posters</p>
            <p>Framed pictures</p>
            <p>Prints & Other</p>
          </div>
        </div>
        <div className='xs:my-6 sm:my-6 lg:my-0'>
          <h3 className='font-bold uppercase'>My Account</h3>
          <div className='mt-3 text-center footer-links'>
            <p>My orders</p>
            <p>My address</p>
            <p>My personal information</p>
            <p>My offers</p>
          </div>
        </div>
        <div className='xs:my-6 sm:my-6 lg:my-0'>
          <h3 className='font-bold uppercase'>Information</h3>
          <div className='mt-3 text-center footer-links'>
            <p>Term of use</p>
            <p>Our Story</p>
            <p>Personal data policy</p>
            <p>Contact Us</p>
            <p>Collaborations</p>
            <p>Questions and answers</p>
          </div>
        </div>
      </div>
      <div className='text-center border-t border-[#595959] w-[65%] mx-auto'>
        <div className='social-media-icons pt-5 pb-5'>
          <div className='flex flex-row space-x-3 items-center justify-center'>
            <h3>Follow us:</h3>
            <a href="https://www.google.com"><img src={FacebookIcon} alt="facebook-icon" /></a>
            <a href="https://www.google.com"><img src={InstagramIcon} alt="insta-icon" /></a>
            <a href="https://www.google.com"><img src={PinterestIcon} alt="pinterest-icon" /></a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer