import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { DataProvider } from './context/DataContext';
import { AuthProvider } from './context/AuthContext';

import PrivateRoutes from './components/routes/PrivateRoutes';
import NotFound from './components/routes/NotFound';

// Application Routes

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Home from './components/Home';
import Album from './components/album/Album';
import DragNDrop from './components/drag-n-drop';
import Basket from './components/basket';
import Edit from './components/album/Edit';
import Payment from './components/payment';
import Cover from './components/album/Cover';


function App() {

  return (
    <Router>
      <AuthProvider>
        <DataProvider>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route element={<PrivateRoutes />} >
              {/* Put routes where user is not allowed to see if they are not logged in. */}
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/album/:album_id" element={<Album />} />
            <Route path="/album/edit/:indices" element={<Edit />} />
            <Route path="/album/cover" element={<Cover />} />
            <Route path="/drag-n-drop" element={<DragNDrop />} />
            <Route path="/basket" element={<Basket />} />
            <Route path="/payment" element={<Payment />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />

        </DataProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
