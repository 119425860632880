const initial_bg_colors = [
    { color: "#FFFFFF" },
    { color: "#EDD2F3" },
    { color: "#EAE3C8" },
    { color: "#A1CAE2" },
    { color: "#B4D9C9" },
    { color: "#DFD3C3" },
    { color: "#DD4A48" },
    { color: "#ED9153" },
    { color: "#FFE162" },
    { color: "#7F33BB" },
    { color: "#3282B8" },
    { color: "#17B794" },
    { color: "#DA1212" },
    { color: "#0D2C71" },
    { color: "#41444B" },
    { color: "#000000" },
]

const initial_txt_colors = [
    { color: "#000000" },
    { color: "#3A3A3A" },
    { color: "#DA1212" },
    { color: "#4B8F4B" },
    { color: "#0D2C71" },
    { color: "#7F33BB" },
    { color: "#ED9153" },
    { color: "#A1CAE2" },
    { color: "#BFBFBF" },
    { color: "#DFD3C3" },
    { color: "#EAE3C8" },
    { color: "#FFFFFF" },
]

const cover_fonts = [
    { main_font: "Raleway", accompanying_font: "Lato" },
    { main_font: "Merriweather", accompanying_font: "Opensans" },
    { main_font: "Urbanist", accompanying_font: "Lato" },
    { main_font: "Playfair", accompanying_font: "Montserrat" },
]




export { initial_bg_colors, initial_txt_colors, cover_fonts }