import React, { useState } from 'react';

function ErrorModal({ message, setErrors }) {
    const [modal, setModal] = useState(true);



    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    const handleExit = () => {
        setModal(!modal);
        setErrors("");
    }

    return (
        <>
            {modal && (
                <div className="modal bg-transparent">
                    <div className="bg-black opacity-40 fixed w-[100vw] h-[100vh]"></div>
                    <div className="modal-content mx-auto bg-white space-y-6">
                        <p className='text-[#E94B45] text-[25px] font-bold capitalize'>Error !</p>
                        <p>{message}</p>
                        <button
                            className='bg-[#E94B45] w-[80px] h-[40px] text-[15px] font-bold text-white rounded-full hover:text-[#E94B45] hover:bg-white hover:transition-all hover:border-[#E94B45] hover:border'
                            onClick={handleExit}>Ok</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ErrorModal