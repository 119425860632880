import React, { useContext, useState, createContext } from "react";

const DataContext = createContext({});

export function useData() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [coverTitle, setCoverTitle] = useState("");
    const [coverSubtitle, setCoverSubTitle] = useState("");
    const [albumImages, setAlbumImages] = useState([])

    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = '';
    };


    const value = {
        loadingStatus,
        setLoadingStatus,
        coverTitle,
        setCoverTitle,
        coverSubtitle,
        setCoverSubTitle,
        albumImages,
        setAlbumImages,
        handleBeforeUnload
    };

    return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}