import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./carousel.css";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper";

import FirstImage from "../../../assets/album-preview/photo-2.jpg";
import SecondImage from "../../../assets/album-preview/photo-3.jpg";


function Carousel({ reference }) {

    const scrollIntoView = () => {
        reference.current.scrollIntoView()
    }

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                keyboard={{
                    enabled: true,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Keyboard, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide className="home-slider"><img src={FirstImage} alt="carousel-1" /></SwiperSlide>
                <SwiperSlide className="home-slider"><img src={SecondImage} alt="carousel-2" /></SwiperSlide>
            </Swiper>
            <button onClick={scrollIntoView} className="text-center -translate-y-40 z-10 absolute left-0 right-0 bg-[#ED4A43] rounded-full text-white xs:w-[300px] sm:w-[300px] lg:w-[400px] h-[60px] mx-auto uppercase font-bold text-[17px] hover:bg-white hover:text-[#ED4A43] hover:border hover:border-[#ED4A43] hover:transition-all">Order Now</button>
        </>
    )
}

export default Carousel