import { useContext, useState } from "react";
import CloseBtn from "../../../assets/cover/Close_S.svg";
import MemopixLogo from "../../../assets/logo.png";
import { db } from "../../../firebase";
import { ref, update } from "firebase/database";
import { useData } from "../../../context/DataContext";


const Change_Cover_Text_Modal = ({ images, set_toggle, session_id }) => {

    const [modal, setModal] = useState(true);

    const { coverTitle, coverSubtitle, setCoverTitle, setCoverSubTitle } = useData();


    let current_album_id = sessionStorage.getItem('album_id');


    const toggleModal = () => {
        setModal(!modal);
        set_toggle(false);
    };

    if (modal) {
        document.body.classList.add("active-modal");
    } else {
        document.body.classList.remove("active-modal");
    }

    const handleTextUpdate = () => {

        images[1].title = coverTitle;
        images[1].subtitle = coverSubtitle;

        update(ref(db, `${session_id}/${parseInt(current_album_id)}`), {
            'images': images,
            'album_id': parseInt(current_album_id),
            'copies': 1
        })
        set_toggle(false);
    }

    return (
        <>
            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="data-modal-content">
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                                <img src={MemopixLogo} className="w-[200px]" />
                                <img src={CloseBtn} onClick={toggleModal} className='w-[45px] cursor-pointer' />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-12 h-[50vh]">
                            <p className="text-center text-[23px] font-semibold mt-12">Here you can edit Title and Subtitle.</p>
                            <div>
                                <label htmlFor="cover_title" className="block mb-6 text-[20px] font-medium text-gray-900">Title</label>
                                <input
                                    type="text"
                                    id="cover_title"
                                    value={coverTitle}
                                    maxLength={25}
                                    onChange={(e) => setCoverTitle(e.target.value)}
                                    className="bg-gray-50 p-5 border border-gray-300 text-gray-900 outline-none text-sm rounded-lg  block w-full"
                                    placeholder="Enter your desired Title"
                                />
                            </div>
                            <div>
                                <label htmlFor="cover_subtitle" className="block mb-6 text-[20px] font-medium text-gray-900 ">Subtitle</label>
                                <input
                                    type="text"
                                    id="cover_subtitle"
                                    value={coverSubtitle}
                                    maxLength={30}
                                    onChange={(e) => setCoverSubTitle(e.target.value)}
                                    className="bg-gray-50 p-5 border border-gray-300 text-gray-900 outline-none text-sm rounded-lg  block w-full"
                                    placeholder="Enter your desired Subtitle"
                                />
                            </div>

                            <div className="mx-auto">
                                <button onClick={() => handleTextUpdate()} className='text-center bg-[#ED4A43] text-white w-[300px] h-[60px] uppercase font-bold text-[17px]'>Update</button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Change_Cover_Text_Modal;
