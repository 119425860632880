import React, { useContext, useRef, useEffect } from "react";
import LoginScreen from "../../assets/login/memo_login.png";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import axios from "axios";
import ErrorModal from "../common/ErrorModal";
import { useData } from "../../context/DataContext";
import ProgressModal from "../common/ProgressModal";
import AuthContext from "../../context/AuthContext";

function Register() {
  let navigate = useNavigate();

  let register_ref = useRef();

  const { authError, setAuthError } = useContext(AuthContext);

  const { loadingStatus, setLoadingStatus } = useData();

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    register,
    watch
  } = useForm();

  async function onhandleSubmit(data) {

    setLoadingStatus(true);
    try {
      axios.post(`${process.env.REACT_APP_BACKEND_API}/sign-up/`, {
        email: data.email,
        first_name: data.name,
        last_name: data.lastname,
        password: data.password,
        gender: data.gender,
        birthday: data.birthday
      }).then((res) => {
        let path = "/login";
        navigate(path);
        setLoadingStatus(false)
      }).catch((err) => {
        setAuthError(err.response.data.message)
        setLoadingStatus(false)
      })
    } catch (error) {
      console.log(error)
    }
  }


  const goToLogin = () => {
    let path = "/login";
    navigate(path);
  }

  useEffect(() => {
    register_ref.current.scrollIntoView('smooth')
  }, [])

  return (
    <div className="login-section" ref={register_ref}>
      {loadingStatus && <ProgressModal uploading={loadingStatus} />}
      {authError !== "" &&
        <ErrorModal message={authError} setErrors={setAuthError} />
      }
      <img className="w-full object-cover h-[60vh]" src={LoginScreen} alt="Image" />

      <div className="login-card w-[85%] mt-[30px] mb-[30px] p-[20px] 2xl:w-[40%] lg:w-[50%] md:w-[60%] sm:w-[65%] xl:p-[50px] lg:p-[40px] md:p-[30px] sm:p-[25px] m-auto items-center flex flex-col bg-loginform xl:translate-y-[-350px] lg:translate-y-[-200px] md:translate-y-[-100px]  sm:translate-y-[-80px]">
        <h3 className="login-card-header text-center text-[20px] py-[10px] xl:text-[40px] lg:text-[35px] md:text-[30px] sm:text-[25px] font-bold xl:py-[50px] lg:py-[40px] md:py-[20px] sm:py-[20px] ">
          Register
        </h3>
        <form className="w-[80%]" onSubmit={handleSubmit(onhandleSubmit)}>
          <div className="form-group text-center pt-[10px] xl:pt-[50px] lg:pt-[30px] md:pt-[20px] sm:pt-[15px] mb-[1rem] flex flex-col">
            <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.3rem]">
              Email
            </label>
            <input
              className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[.75rem] sm:text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
              id="email"
              name="email"
              type='email'
              required={true}
              {...register("email", {
                required: "Email is Required!!!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                }
              })}
              error={Boolean(errors.email)}
              onKeyUp={() => { trigger("email") }}
            />
            {errors.email && (
              <small className="text-[13px] text-[#E94B45] text-left pt-[5px]">{errors.email.message}</small>
            )}
          </div>

          <div className="form-group text-center pt-[10px] xl:pt-[30px] lg:pt-[25px]  md:pt-[15px] sm:pt-[10px] mb-[1rem] flex flex-col">
            <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.5rem]">
              First Name
            </label>
            <input
              className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[.75rem] sm:text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
              name='name'
              type="text"
              required={true}
              defaultValue=""
              {...register("name", { required: "First Name is Required!!!" })}
              onKeyUp={() => { trigger("name") }}
            />
            {errors.name && (
              <span className="text-[13px] text-[#E94B45] text-left pt-[5px]">First Name is Required!!!</span>
            )}
          </div>

          <div className="form-group text-center pt-[10px] xl:pt-[30px] lg:pt-[25px]  md:pt-[15px] sm:pt-[10px] mb-[1rem] flex flex-col">
            <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.5rem]">
              Last Name
            </label>
            <input
              className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[.75rem] sm:text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
              name='lastname'
              type="text"
              required={true}
              defaultValue=""
              {...register("lastname", { required: "Last Name is Required!!!" })}
              onKeyUp={() => { trigger("lastname") }}
            />
            {errors.name && (
              <span className="text-[13px] text-[#E94B45] text-left pt-[5px]">Last Name is Required!!!</span>
            )}
          </div>

          <div className="form-group text-center pt-[10px] xl:pt-[30px] lg:pt-[25px]  md:pt-[15px] sm:pt-[10px] mb-[1rem] flex flex-col">
            <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.5rem]">
              Password
            </label>
            <input
              className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[1.4rem] sm:text-[2rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
              name='password'
              id="password"
              type='password'
              autoComplete='off'
              required={true}
              {...register("password", {
                required: "You must specify a password",
                pattern: {
                  value: '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){    1,})(?!.*\s).{8,}$',
                  message: "Password should contain at least one number and one    special character"
                },
                minLength: {
                  value: 8,
                  message: "Password must be more than 8 characters"
                },
                maxLength: {
                  value: 20,
                  message: "Password must be less than 20 characters"
                },
              })}
              onKeyUp={() => { trigger("password") }}
              error={Boolean(errors.password)}
            />
            {errors.password && (
              <span className="text-[13px] text-[#E94B45] text-left pt-[5px]">{errors.password.message}</span>
            )}
          </div>

          <div className="form-group text-center pt-[10px] xl:pt-[30px] lg:pt-[25px]  md:pt-[15px] sm:pt-[10px] mb-[1rem] flex flex-col">
            <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.5rem]">
              Gender
            </label>
            <div className="flex flex-row space-x-6">
              <div className="flex flex-row space-x-2">
                <input
                  className="h-[18px] xl:h-[22px] lg:h-[22px] md:h-[22px] sm:h-[22px] text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
                  type="radio"
                  value="m"
                  name="gender"
                  required={true}
                  {...register("gender", { required: "Gender is Required!!!" })}
                  onKeyUp={() => { trigger("gender") }}
                />
                <p className="text-[14px] grid items-center">Male</p>
              </div>
              <div className="flex flex-row space-x-2">
                <input
                  className="h-[18px] xl:h-[22px] lg:h-[22px] md:h-[22px] sm:h-[22px] text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
                  type="radio"
                  value="f"
                  name="gender"
                  required={true}
                  {...register("gender", { required: "Gender is Required!!!" })}
                  onKeyUp={() => { trigger("gender") }}
                />
                <p className="text-[14px] grid items-center">Female</p>
              </div>
            </div>
          </div>

          <div className="form-group text-center pt-[10px] xl:pt-[30px] lg:pt-[25px]  md:pt-[15px] sm:pt-[10px] mb-[1rem] flex flex-col">
            <label className="text-start text-[14px] sm:text-[18px] mb-[0.3rem] sm:mb-[0.5rem]">
              Birthday
            </label>
            <input
              className="h-[30px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-[.75rem] sm:text-[1rem] border-2 border-solid border-borderLoginForm py-[.375rem] px-[.75rem] rounded outline-1"
              type="date"
              name="birthday"
              required={true}
              defaultValue=""
              {...register("birthday", { required: "Birthday is Required!!!" })}
              onKeyUp={() => { trigger("birthday") }}
            />
          </div>

          <div className="form-group text-center pt-[10px] xl:pt-[50px] lg:pt-[30px]  md:pt-[20px] sm:pt-[15px] mb-[1rem] flex flex-col">
            <button
              className="bg-[#E94B45] w-full h-[35px] xl:h-[60px] lg:h-[60px] md:h-[50px] sm:h-[40px] text-white font-bold md:text-[20px] sm:text-[15] "
              type="submit"
            >
              Register
            </button>
          </div>

        </form>
        <button
          className="text-[14px] sm:text-[16px]"
          onClick={goToLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Register;
