import React, { useEffect, useState } from 'react'

const useWindowResizer = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const handleWindowChange = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {

        handleWindowChange();

        window.addEventListener('resize', handleWindowChange)

        return () => {
            window.removeEventListener('resize', handleWindowChange)
        }
    }, [])

    return { windowSize }
}

export default useWindowResizer