import { useState, useEffect } from "react";
import axios from "axios";
import { useData } from "../context/DataContext";

const useFetch = (apiUrl, intialState) => {
    const [data, setData] = useState(intialState);

    const { setLoadingStatus } = useData();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingStatus(true);
                await axios.get(apiUrl).then((res) => {
                    setData(res.data.data)
                    setLoadingStatus(false);
                }).catch((err) => {
                    console.log(err)
                    setLoadingStatus(false);
                })
            } catch (error) {
                console.log("error")
                console.log(error)
            }
        };
        fetchData();
    }, [])

    return { data };

}

export default useFetch;