const createSructure = (albumImages, default_length, setAlbumImages, setDefaultImages) => {
    console.log(default_length)
    let new_arr = [...albumImages];

    for (let i = 0; i < default_length; i++) {
        // delete
        if (i === 0) {
            new_arr.push({
                src: 'delete',
                file: 'icon',
            })
        }
        // cover
        if (i === 1) {
            new_arr.push({
                src: 'cover',
                file: 'icon',
                option: 0,
                fill: false,
                x: 0,
                y: 0,
                rotate: 0,
                description: '',
                title: '',
                subtitle: '',
                text_color: '',
                background_color: '',
                main_font: '',
                second_font: ''
            })
        }
        // empty
        if (i > 1 && i < 5) {
            new_arr.push({
                src: 'empty',
                file: 'icon',
            })
        }
        // back cover
        if (i === default_length - 1) {
            new_arr.push({
                src: 'back',
                file: 'icon',
            })
        }
        if (i === default_length - 2) {
            new_arr.push({
                src: 'delete',
                file: 'icon',
            })
        }
        // back empty
        if (i > default_length - 5 && i < default_length - 2) {
            new_arr.push({
                src: 'empty',
                file: 'icon',
            })
        }

        // Images
        if (i > 4 && i <= default_length - 5) {
            new_arr.push({
                src: 'icon',
                cropped_src: "",
                file: 'icon',
                padding_option: 0,
                fill: false,
                x: 0,
                y: 0,
                rotate: 0,
                description: ''
            })
        }

    }

    setAlbumImages(new_arr);
}

export {
    createSructure
}