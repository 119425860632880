import React, { useState, useCallback, useRef, useEffect } from 'react'
import { getSessionId } from '../../globalFunctions/functions';
import { db } from '../../firebase';
import { ref, get } from "firebase/database";
import { postImageFirebase } from '../../globalFunctions/functions';
import uploadWithProgress from '../../globalFunctions/uploadWithProgress';
import ProgressModal from '../common/ProgressModal';

// Swiper Css

import "./index.css"

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';

import { useNavigate, useParams } from 'react-router-dom';
import { useData } from '../../context/DataContext';

import Indicies from './Indicies/Indicies';
import useWindowResizer from '../../hooks/useWindowResizer';
import NotAllowed from '../common/NotAllowed';

function Edit() {


    let selected_image_index = parseInt(sessionStorage.getItem('selected_image_index'))
    const [swiperRef, setSwiperRef] = useState();

    const [fireBaseImages, setFireBaseImages] = useState([]);
    const [progress, setProgress] = useState(0);


    const { loadingStatus, setLoadingStatus, albumImages, setAlbumImages } = useData();

    const cropper_ref = useRef({});

    const history = useNavigate();

    const session_id = getSessionId();

    let current_album_id = sessionStorage.getItem('album_id');

    const image_ref = useRef();

    const { windowSize } = useWindowResizer();


    useEffect(() => {
        get(ref(db, `${session_id}`)).then(snapshot => {
            if (snapshot.exists()) {
                let firebase_images = snapshot.val()[current_album_id];

                let firebase_images_filtered = snapshot.val()[current_album_id].images;
                setFireBaseImages(firebase_images)
                setAlbumImages(firebase_images_filtered)
            }
        })
    }, [])

    if (windowSize.width < 1300) {
        return <NotAllowed />
    }

    const setFit = (e, id) => {
        albumImages[id].fill = false;
        albumImages[id].padding_option = 0;
        albumImages[id].cropped_src = "";
        setAlbumImages([...albumImages])
    }

    const setFill = (e, id) => {
        albumImages[id].fill = true;
        albumImages[id].padding_option = 0;
        setAlbumImages([...albumImages])
    }

    const setFitFull = (id, e) => {
        albumImages[id].padding_option = 0;
        setAlbumImages([...albumImages]);
    }

    const setFitMedium = (id, e) => {
        albumImages[id].padding_option = 1;
        setAlbumImages([...albumImages]);
    }

    const setFitSmall = (id) => {
        albumImages[id].padding_option = 2;
        setAlbumImages([...albumImages]);
    }

    const setFitSmallMessage = (id) => {
        albumImages[id].padding_option = 3;
        setAlbumImages([...albumImages]);
    }

    const rotateImage = (image_id) => {
        let image = null;
        image = albumImages[image_id];
        if (image.rotate === 270) {
            image.rotate = 0;
            setAlbumImages([...albumImages])
        } else {
            image.rotate += 90;
            setAlbumImages([...albumImages])
        }
    }

    const setFillFullImage = (index) => {
        albumImages[index].padding_option = 0;
        setAlbumImages([...albumImages]);
    }

    const setFillMedium = (index) => {
        albumImages[index].padding_option = 1;
        setAlbumImages([...albumImages]);
    }

    const setFillSmall = (index) => {
        albumImages[index].padding_option = 2;
        setAlbumImages([...albumImages]);
    }

    const setCustomText = (e, selected_album) => {

        let custom_text = e.target.value;
        selected_album.description = custom_text;

        setAlbumImages([...albumImages])

    }
    const setFillSmallMessage = (index) => {
        albumImages[index].padding_option = 3;
        setAlbumImages([...albumImages]);
    }

    const saveChanges = async () => {

        let urls = {};

        if (Object.keys(cropper_ref.current).length === 0) {
            await postImageFirebase(albumImages, false, current_album_id, fireBaseImages)
            setLoadingStatus(false);
            goBackToAlbum();
        } else {
            Object.keys(cropper_ref.current).forEach(async (key) => {
                const cropped_image_base_64 = cropper_ref.current[key].getImage().toDataURL();
                const res = await fetch(cropped_image_base_64);
                const blob = await res.blob();
                blob.name = `cropped_image-${key}`;
                setLoadingStatus(true);
                const url = uploadWithProgress(blob, setProgress);
                url.then((url) => urls[key] = url)
                    .then(() => {
                        for (let i = 0; i < albumImages.length; i++) {
                            if (i.toString() === key) {
                                albumImages[i].cropped_src = urls[i];
                            }
                        }
                    }).then(() => {
                        setAlbumImages([...albumImages]);
                    }).finally(async () => {
                        await postImageFirebase(albumImages, false, current_album_id, fireBaseImages)
                        setLoadingStatus(false);
                        goBackToAlbum();
                    }).catch(err => console.log("Err", err))
            });
        }

    }



    const goBackToAlbum = () => {
        history("/drag-n-drop")
    }



    return (
        <div className='bg-[#DCDCDC] h-[100vh]'>
            <div className='flex flex-row justify-end gap-6 items-center p-6'>
                <button
                    className='bg-gray-400 w-[120px] h-[50px] text-[16px] rounded-full font-bold text-white hover:transition-all hover:opacity-90'
                    onClick={() => goBackToAlbum()}
                >
                    Go Back
                </button>
                <button
                    className='bg-[#E94B45] w-[140px] h-[50px] text-[16px] font-bold text-white rounded-full hover:text-[#E94B45] hover:bg-white hover:transition-all hover:border-[#E94B45] hover:border'
                    onClick={() => saveChanges()}
                >
                    Save
                </button>
            </div>

            {loadingStatus &&
                <ProgressModal progress={progress} uploading={loadingStatus} />
            }
            <div className='w-full text-center mx-auto flex flex-col justify-center'>

                <div className='lg:w-[1200px] 2xl:w-[1300px] mx-auto flex flex-row justify-between items-center h-[85vh]'>
                    <Indicies
                        selected_image_index={selected_image_index}
                        setSwiperRef={setSwiperRef}
                        items={albumImages}
                        setFit={setFit}
                        setFill={setFill}
                        rotateImage={rotateImage}
                        image_ref={image_ref}
                        setCustomText={setCustomText}
                        setFitFull={setFitFull}
                        setFitMedium={setFitMedium}
                        setFitSmall={setFitSmall}
                        setFitSmallMessage={setFitSmallMessage}
                        setFillFullImage={setFillFullImage}
                        setFillMedium={setFillMedium}
                        setFillSmall={setFillSmall}
                        setFillSmallMessage={setFillSmallMessage}
                        cropper_ref={cropper_ref}
                    />
                </div>

            </div>

        </div>


    )
}

export default Edit