import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { projectFireStore } from '../../firebase';

import axios from 'axios';

// Left Images
import Left_1 from '../../assets/album-preview/photo-1.jpg';
import Left_2 from '../../assets/album-preview/photo-2.jpg';
import Left_3 from '../../assets/album-preview/photo-3.jpg';
import Left_4 from '../../assets/album-preview/single-photo.jpg';
// Main Image
import { getFirebaseDataOfSession, setAlbumIndexSession } from '../../globalFunctions/functions';
import useFetch from '../../hooks/useFetch';
import { useData } from '../../context/DataContext';
import ProgressModal from '../common/ProgressModal';


function Album() {

    const [currentAlbum, setCurrentAlbum] = useState({});
    const [mainImage, setMainImage] = useState(Left_1)
    const route_params = useParams();
    const history = useNavigate();


    let current_album_id = parseInt(route_params.album_id);

    sessionStorage.setItem('album_id', route_params.album_id);


    // Gets selected album 

    const { data } = useFetch(`${process.env.REACT_APP_BACKEND_API}/album/type/${current_album_id}/`, currentAlbum);

    const { loadingStatus } = useData();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigateToDragNDrop = () => {
        localStorage.setItem('album_no_pages', String(data.no_page))
        sessionStorage.setItem('album_default_price', String(data.default_price))
        sessionStorage.setItem('album_name', String(data.name))

        history('/drag-n-drop')

    }

    const handleImage = (e) => {
        setMainImage(e.target.src);
    }

    useEffect(() => {
        setAlbumIndexSession(current_album_id)
    }, [])


    return (
        <div className='mx-auto grid xs:grid-rows-2 sm:grid-rows-1 lg:grid-cols-3 xs:my-6 sm:my-6 lg:my-12'>
            <div className='flex xs:flex-row sm:flex-row flex-wrap lg:flex-col w-auto xs:items-center sm:items-center lg:items-end xs:mr-0 sm:mr-0  lg:mr-5 space-y-3 justify-evenly'>
                <img src={Left_1} className='w-[180px] h-[140px] object-cover cursor-pointer' alt='left-1' onClick={(e) => handleImage(e)} />
                <img src={Left_2} className='w-[180px] h-[140px] object-cover cursor-pointer' alt='left-2' onClick={(e) => handleImage(e)} />
                <img src={Left_3} className='w-[180px] h-[140px] object-cover cursor-pointer' alt='left-3' onClick={(e) => handleImage(e)} />
                <img src={Left_4} className='w-[180px] h-[140px] object-cover cursor-pointer' alt='left-4' onClick={(e) => handleImage(e)} />
            </div>
            <div className='xs:my-3 sm:my-3 lg:my-0'>
                <img src={mainImage} className='xs:w-[95%] sm:w-[95%] mx-auto lg:w-[800px] h-full object-cover' alt='main' />
            </div>
            <div className='flex flex-col xs:items-center sm:items-center lg:items-start xs:justify-center sm:justify-center lg:justify-end xs:ml-0 lg:ml-5 xs:space-y-6 sm:space-y-6 lg:space-y-12'>
                <div className='mb-5 xs:w-[90%] sm:w-[90%] lg:w-[50%]'>
                    <p className='font-bold text-[26px]'>{data.name}</p>
                    <p className='text-[18px] font-[400]'>{data.description}</p>
                </div>
                <div className='mb-5 xs:w-[90%] sm:w-[90%] lg:w-[50%]'>
                    <p className='font-bold text-[26px]'>Materiali</p>
                    <p className='text-[18px] font-[400]'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna</p>
                </div>
                <div className='mb-5 xs:w-[90%] sm:w-[90%] lg:w-[50%]'>
                    <p className='font-bold text-[26px]'>Cmimi</p>
                    <p className='text-[18px] font-[400]'>{data.default_price} ALL {"("} + {data.price_for_page} ALL per new page {")"}</p>
                </div>
                <button onClick={navigateToDragNDrop} className='text-center bg-[#ED4A43] text-white w-[300px] h-[60px] uppercase font-bold text-[17px] rounded-full hover:bg-white hover:text-[#ED4A43] hover:border hover:border-[#ED4A43] hover:transition-all'>Order Now</button>

            </div>

            {loadingStatus && <ProgressModal uploading={loadingStatus} />}

            {/* TODO : Instagram Feed */}

        </div>
    )
}

export default Album;

