import React from 'react'
import { useLocation } from 'react-router-dom'

function Option10({ image, onLoad }) {

    const location = useLocation();


    if (location.pathname.includes("basket")) {
        return (
            <div className='flex flex-col justify-center w-[200px] h-[160px] drop-shadow-lg scale-[0.8] overflow-hidden'
                style={{
                    color: image.text_color,
                    backgroundColor: image.background_color
                }}
            >
                <img
                    src={image.cropped_src ? image.cropped_src : image.src}
                    className="object-contain w-[80px] mx-auto my-2"
                    alt=''
                    onLoad={onLoad}
                />
                <div className='text-center px-4 pb-2'>
                    <p className='text-[10px] font-bold'>{image.title}</p>
                    <p className='text-[8px] font-light'>{image.subtitle}</p>
                </div>


            </div>
        )
    }

    return (

        <div className='flex flex-col'
            style={{
                color: image.text_color,
                backgroundColor: image.background_color
            }}
        >

            <img
                src={image.cropped_src ? image.cropped_src : image.src}
                className="object-contain h-[35px] mt-1"
                alt=''
                onLoad={onLoad}
            />
            <div className='px-2 text-center'>
                <p className='text-[5px] pt-2'>{image.title}</p>
                <p className='text-[3px] font-light'>{image.subtitle}</p>
            </div>


        </div>
    )
}

export default Option10