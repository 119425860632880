import React from 'react'

function Draggable({ onDragStart, onDragEnter, onDragEnd, onDragOver, children }) {
  return (
    <div className='box1 cursor-move'
      draggable
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      {children}
    </div>
  )
}

export default Draggable