import React, { useState, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor';
import { useData } from '../../../../context/DataContext';

function FullImageCropper({ image, image_current_option, image_arr, id, cropper_ref }) {

  const [customCropSize, setCustomCropSize] = useState({
    width: 550,
    height: 550,
  })

  const { loadingStatus, setLoadingStatus } = useData();

  const [position, setPosition] = useState({
    x: 0,
    y: 0
  })


  const fixCroppingAccordingToOption = () => {

    // ID that's here comes from props

    var canvas_div = document.querySelector(`#cropper-container-${id} canvas`)

    if (image_current_option === 0) {

      canvas_div.classList.remove("medium-image-crop")
      canvas_div.classList.remove("small-image-crop")
      canvas_div.classList.remove("crop-text")

      setCustomCropSize({
        width: 550,
        height: 550,
      })

      setLoadingStatus(false);
    } else if (image_current_option === 1) {

      setCustomCropSize({
        width: 450,
        height: 450
      })
      canvas_div.classList.add("medium-image-crop")
      canvas_div.classList.remove("small-image-crop")
      canvas_div.classList.remove("crop-text")



    } else if (image_current_option === 2) {
      setCustomCropSize({
        width: 330,
        height: 330
      })

      canvas_div.classList.add("small-image-crop")
      canvas_div.classList.remove("crop-text")
      canvas_div.classList.remove("medium-image-crop")


    } else if (image_current_option === 3) {
      setCustomCropSize({
        width: 350,
        height: 350
      })
      canvas_div.classList.add("crop-text")
      canvas_div.classList.remove("small-image-crop")
    
    }
  }

  useEffect(() => {
    fixCroppingAccordingToOption();
  }, [image_current_option])


  const addToRefs = (el) => {
    if (el && !cropper_ref.current.hasOwnProperty(id)) {
      cropper_ref.current[id] = el;
    }
  }

  const handlePositionChange = (position) => {

    setPosition({
      x: position.x,
      y: position.y
    });

    image_arr.x = position.x;
    image_arr.y = position.y;
  }

  return (
    <div className='crop-container' id={`cropper-container-${id}`}>
      <AvatarEditor
        ref={addToRefs}
        image={image}
        width={customCropSize.width}
        height={customCropSize.height}
        border={0}
        rotate={image_arr.rotate}
        crossOrigin={'anonymous'}
        onPositionChange={(position) => handlePositionChange(position)}
        position={{
          x: image_arr.x,
          y: image_arr.y
        }}
      />
    </div>

  )
}

export default FullImageCropper
