import React, { useContext, useState, createContext } from "react";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const [token, setToken] = useState("");
    const [user, setUser] = useState({});
    const [authError, setAuthError] = useState("");

    const value = {
        token,
        setToken,
        user,
        setUser,
        authError,
        setAuthError
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext;