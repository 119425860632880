import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { projectStorage } from '../firebase';
import { getSessionId } from './functions';

const uploadWithProgress = (file, setProgress) => {
    const session_id = getSessionId();

    return new Promise((resolve, reject) => {
        const storageRef = ref(projectStorage, `${session_id}/${file.name}`);
        const upload = uploadBytesResumable(storageRef, file);

        setProgress(0);

        upload.on(
            'state_changed',
            (snapShot) => {
                const progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
                setProgress((prevProgress) =>
                    Math.max(progress, prevProgress)
                );
            },
            (error) => {
                reject(error);
            },
            async () => {
                try {
                    const url = await getDownloadURL(storageRef);
                    resolve(url);
                } catch (error) {
                    reject(error);
                }
            }
        );
    });
};
export default uploadWithProgress;