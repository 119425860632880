import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.css';
import Image from './components/Image';

import { db } from '../../firebase';
import { ref, get } from "firebase/database";


//Icons
import AddNew from "../../assets/svgs/add.svg";
import Instagram from "../../assets/svgs/insta.svg";
import GPH from "../../assets/svgs/googlephotos.svg";
import Draggable from './components/Draggable';
import { postImageFirebase } from "../../globalFunctions/functions";
import Help from "./components/Help";

import uploadWithProgress from '../../globalFunctions/uploadWithProgress';
import ProgressModal from '../common/ProgressModal';
import { useData } from '../../context/DataContext';
import useWindowResizer from '../../hooks/useWindowResizer';
import NotAllowed from '../common/NotAllowed';

// Functions

import { createSructure } from './common';

function DragNDrop() {

    const history = useNavigate();
    const location = useLocation();

    let albumimages_length = parseInt(localStorage.getItem('album_no_pages'));

    let current_album_id = sessionStorage.getItem('album_id');

    const session_id = sessionStorage.getItem('sessionId');

    const [progress, setProgress] = useState(0);

    const [defaultImages, setDefaultImages] = useState([]);

    const { loadingStatus, setLoadingStatus, albumImages, setAlbumImages, handleBeforeUnload } = useData();

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const { windowSize } = useWindowResizer();

    const uploadImage = async (event) => {
        const files = event.target.files;

        const urls = [];

        setLoadingStatus(true);

        if (files.length === 0)
            return;
        for (let file of files) {
            const mimeType = await file.type;

            if (mimeType.match(/image\/*/) == null) {
                return;
            }

            const url = uploadWithProgress(file, setProgress);


            url.then((response) => urls.push(response)).then(() => {
                for (let i = 0; i <= albumImages.length; i++) {
                    if (albumImages[i].src === 'empty') {
                        continue
                    }
                    if (albumImages[i].src === 'cover') {
                        albumImages[i].src = urls[0];
                        setAlbumImages([...albumImages]);
                    }
                    if (albumImages[i].src === 'icon') {
                        if (albumImages[0].src !== 'cover') {
                            for (let j = 0; j < urls.length; j++) {
                                albumImages[i].src = urls[j];
                            }
                        }
                        else {
                            for (let j = 1; j < urls.length; j++) {
                                albumImages[i].src = urls[j];
                            }
                        }

                        setAlbumImages([...albumImages]);
                        break;
                    }
                }
            }).then(async () => {
                await postImageFirebase(albumImages, false, current_album_id, defaultImages);
                setLoadingStatus(false);
            })


        }

    }

    const handleSort = async () => {
        if (dragOverItem.current > 4 && dragOverItem.current <= albumImages.length - 5) {
            let _imageItems = [...albumImages];

            const draggedItemContent = _imageItems.splice(dragItem.current, 1)[0];

            _imageItems.splice(dragOverItem.current, 0, draggedItemContent);

            dragItem.current = null;
            dragOverItem.current = null;

            setAlbumImages(_imageItems);

            postImageFirebase(_imageItems, false, current_album_id, defaultImages);

        } else {
            alert("You are not allowed to place here!")
        }
    }


    const deleteImageAtCurrentIndex = (index) => {
        let new_array = [...albumImages];
        albumImages[index].src = 'icon';
        setAlbumImages(new_array);
        setLoadingStatus(true);
        postImageFirebase(albumImages, false, current_album_id, defaultImages)
        setLoadingStatus(false);
    }



    useEffect(() => {
        createSructure([], albumimages_length, setAlbumImages, setDefaultImages)
    }, [current_album_id])

    useEffect(() => {
        get(ref(db, `${session_id}`)).then(snapshot => {

            if (snapshot.exists()) {
                let firebase_images = snapshot?.val()[current_album_id].images;
                let firebase_album_id = snapshot?.val()[current_album_id].album_id;


                if (firebase_album_id === parseInt(current_album_id)) {
                    setAlbumImages(firebase_images)
                } else {
                    createSructure([], albumimages_length, setAlbumImages, setDefaultImages)
                }
            }
        })
    }, [])



    if (windowSize.width < 1300) {
        return <NotAllowed />
    }

    const navigateToBasket = () => {
        if (albumImages[1].cropped_src !== undefined) {
            history('/basket')
        } else {
            alert("Please edit your cover page :) ")
            return
        }
    }

    const navigateToAlbum = () => {
        history(`/album/${current_album_id}`);
    }

    if (albumImages[1]?.src === 'cover') {
        return (
            <div className='my-6 flex flex-row h-[50vh]'>
                <div className='flex flex-col w-[6%] items-center space-y-6'>
                    <label htmlFor="upload"
                        className='bg-white rounded-full p-4 cursor-pointer shadow-md hover:opacity-80'>
                        <img src={AddNew} alt="add-new" className='w-[25px] h-[25px]' />
                        <input type="file" id="upload" className='hidden' multiple accept='image/*'
                            onChange={uploadImage} />
                    </label>
                    <label htmlFor="get-images-from-google"
                        className='bg-white rounded-full p-4 cursor-pointer shadow-md hover:opacity-80'>
                        <img src={GPH} alt="insta" className='w-[25px] h-[25px]' />
                        <input type="button" id="get-images-from-google" className='hidden'
                            onClick={() => console.log('clicked gp')} />
                    </label>
                    <label htmlFor="get-images-from-insta"
                        className='bg-white rounded-full p-4 cursor-pointer shadow-md hover:opacity-80'>
                        <img src={Instagram} alt="insta" className='w-[25px] h-[25px]' />
                        <input type="button" id="get-images-from-insta" className='hidden'
                            onClick={() => console.log('clicked ig')} />
                    </label>
                </div>
                <div className='flex justify-center w-full h-[50vh] items-center text-center'>
                    <Help />
                </div>
                {loadingStatus &&
                    <ProgressModal progress={progress} uploading={loadingStatus} />
                }
            </div>
        )
    }



    return (
        <div className='my-6'>
            {loadingStatus &&
                <ProgressModal progress={progress} uploading={loadingStatus} />
            }
            <div className='flex flex-row items-baseline'>
                <div className='flex flex-col w-[6%] items-center space-y-6'>
                    <label htmlFor="upload"
                        className='bg-white rounded-full p-4 cursor-pointer shadow-md hover:opacity-80'>
                        <img src={AddNew} alt="add-new" className='w-[25px] h-[25px]' />
                        <input type="file" id="upload" className='hidden' multiple accept='image/*'
                            onChange={uploadImage} />
                    </label>
                    <label htmlFor="get-images-from-google"
                        className='bg-white rounded-full p-4 cursor-pointer shadow-md hover:opacity-80'>
                        <img src={GPH} alt="insta" className='w-[25px] h-[25px]' />
                        <input type="button" id="get-images-from-google" className='hidden'
                            onClick={() => console.log('clicked gp')} />
                    </label>
                    <label htmlFor="get-images-from-insta"
                        className='bg-white rounded-full p-4 cursor-pointer shadow-md hover:opacity-80'>
                        <img src={Instagram} alt="insta" className='w-[25px] h-[25px]' />
                        <input type="button" id="get-images-from-insta" className='hidden'
                            onClick={() => console.log('clicked ig')} />
                    </label>
                </div>
                <div className='album-images-list flex flex-row flex-wrap mx-auto space-y-8'>
                    {albumImages?.map((item, index) => {
                        return (
                            <div className='drag-n-drop-list' key={index}>
                                {index > 4 && item.src !== 'icon' && index <= albumImages.length - 5 ?
                                    <Draggable
                                        onDragStart={(e) => (dragItem.current = index)}
                                        onDragEnter={(e) => (dragOverItem.current = index)}
                                        onDragEnd={handleSort}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <Image item={item} index={index} deleteItem={deleteImageAtCurrentIndex} />
                                    </Draggable>
                                    :
                                    <div
                                        className='box1'
                                    >
                                        <Image item={item} index={index} deleteItem={deleteImageAtCurrentIndex}
                                            customClass="cursor-not-allowed" />
                                    </div>}
                            </div>

                        )
                    })}
                </div>
            </div>
            <div className='flex flex-row items-center text-center justify-center my-12 space-x-12'>
                <button
                    className='bg-[#BBB9B9] w-[250px] h-[60px] text-[18px] font-bold text-white rounded-full'
                    onClick={navigateToAlbum}
                >
                    Back to album
                </button>
                <button className='bg-[#E94B45] w-[250px] h-[60px] text-[18px] font-bold text-white rounded-full hover:bg-white hover:text-[#ED4A43] hover:border hover:border-[#ED4A43] hover:transition-all' onClick={navigateToBasket}>Continue</button>
            </div>


        </div>


    )
}

export default DragNDrop

