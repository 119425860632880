import React, { useState, useEffect, useRef, useCallback, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Change_Cover_Text_Modal from './options/Change_Cover_Text_Modal';
import { initial_bg_colors, initial_txt_colors, cover_fonts } from './cover-assets';
import PenIcon from "../../assets/cover/pen-icon.png";
import PhotoIcon from "../../assets/cover/photo-icon.png";


// Firebase
import { db } from '../../firebase';
import { ref, get } from "firebase/database";
import { getSessionId, postImageFirebase } from '../../globalFunctions/functions';

// Swiper

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// Icons

import leftArrow from "../../assets/cover/left-arrow.png"
import rightArrow from "../../assets/cover/right-arrow.png"

// Cover Options 

import Option1 from './options/cover-options/Option1';
import CoverImagePicker from './options/CoverImagePicker';
import uploadWithProgress from '../../globalFunctions/uploadWithProgress';
import ProgressModal from '../common/ProgressModal';
import { useData } from '../../context/DataContext';
import useWindowResizer from '../../hooks/useWindowResizer';
import NotAllowed from '../common/NotAllowed';

// Import options with lazy due to useRef disorder

const Option2 = lazy(() => import('./options/cover-options/Option2'));
const Option3 = lazy(() => import('./options/cover-options/Option3'));
const Option4 = lazy(() => import('./options/cover-options/Option4'));
const Option5 = lazy(() => import('./options/cover-options/Option5'));
const Option6 = lazy(() => import('./options/cover-options/Option6'));
const Option7 = lazy(() => import('./options/cover-options/Option7'));
const Option8 = lazy(() => import('./options/cover-options/Option8'));
const Option9 = lazy(() => import('./options/cover-options/Option9'));
const Option10 = lazy(() => import('./options/cover-options/Option10'));


function Cover() {

    const history = useNavigate();

    const { windowSize } = useWindowResizer();

    let current_album_id = sessionStorage.getItem('album_id');
    const session_id = getSessionId();



    const [coverImage, setCoverImage] = useState("");
    const [uploadedImages, setUploadedImages] = useState([]);
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedTextColor, setSelectedTextColor] = useState("");
    const [selectedFont, setSelectedFont] = useState("");
    const [selectedSecondFont, setSelectedSecondFont] = useState("");

    const [toggleTextEdit, setToggleTextEdit] = useState(false);
    const [toggleImagePicker, setToggleImagePicker] = useState(false);

    const [progress, setProgress] = useState(0);


    const { loadingStatus, setLoadingStatus } = useData();

    const [imageDimensions, setImageDimensions] = useState({
        width: "",
        height: ""
    })


    const cover_crop = useRef({});

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);


    // This runs for the initial cover image

    useEffect(() => {
        get(ref(db, `${session_id}`)).then(snapshot => {
            if (snapshot.exists()) {
                let firebase_images = snapshot.val()[current_album_id].images;
                setCoverImage(firebase_images[1]);
                setUploadedImages(firebase_images);

                setSelectedFont(cover_fonts[0].main_font)
                setSelectedSecondFont(cover_fonts[0].accompanying_font)

            }
        })
    }, [])

    useEffect(() => {

        if (uploadedImages[1]?.background_color) {
            setSelectedColor(uploadedImages[1].background_color)
        } else {
            setSelectedColor(initial_bg_colors[0].color)
        }

        if (uploadedImages[1]?.text_color) {
            setSelectedTextColor(uploadedImages[1].text_color)
        } else {
            setSelectedTextColor(initial_txt_colors[0].color)
        }

    }, [uploadedImages])

    function getBgColor(bgColor) {
        setSelectedColor(bgColor);

        uploadedImages[1].background_color = bgColor;
        setUploadedImages([...uploadedImages]);
    }

    function getTxtColor(txtColor) {
        setSelectedTextColor(txtColor);

        uploadedImages[1].text_color = txtColor;
        setUploadedImages([...uploadedImages]);
    }

    function getSelectedFont(main_font, accompanying_font) {
        setSelectedFont(main_font);
        setSelectedSecondFont(accompanying_font);
        coverImage.main_font = main_font;
        coverImage.second_font = accompanying_font;

        uploadedImages[1].main_font = main_font;
        uploadedImages[1].second_font = accompanying_font;

        setUploadedImages([...uploadedImages])

    }


    const updateCover = async () => {

        let urls = [];
        let swiper_index = sliderRef?.current?.swiper.realIndex;

        uploadedImages[1].option = swiper_index + 1;

        if (imageDimensions.height > 1000) {
            const image_option = uploadedImages[1].option;

            const _image = cover_crop.current[image_option].getImage().toDataURL();
            const res = await fetch(_image)
            const blob = await res.blob();

            const url = uploadWithProgress(blob, setProgress);

            setLoadingStatus(true);

            url.then((url) => urls.push(url)).then(() =>
                uploadedImages[1].cropped_src = urls[0]
            ).then(() => {
                setUploadedImages([...uploadedImages]);
            }).finally(async () => {
                await postImageFirebase(uploadedImages, false, current_album_id, []);
                setLoadingStatus(false);
                history("/drag-n-drop")
            }).catch((err) => console.log(err))

        } else {
            uploadedImages[1].cropped_src = '';
            setUploadedImages([...uploadedImages]);
            await postImageFirebase(uploadedImages, false, current_album_id, []);
            history("/drag-n-drop")
        }

    }

    const getImageDimensions = () => {
        let _image = new Image();

        _image.onload = function () {
            setImageDimensions({ width: this.width, height: this.height })
        }
        _image.src = coverImage.src;

    }


    useEffect(() => {
        getImageDimensions();
    }, [coverImage.src, coverImage.cropped_src])

    if (windowSize.width < 1300) {
        return <NotAllowed />
    }



    return (
        <>
            {loadingStatus &&
                <ProgressModal progress={progress} uploading={loadingStatus} />
            }
            <div className='border-b pt-12 pb-4'>
                <p className='text-center  text-[25px] uppercase font-[500]'>Cover</p>
            </div>
            <div className='flex flex-row my-6 items-center'>
                <div className='w-[30%] flex flex-col justify-center items-baseline mx-24'>
                    <div className='choose-font-wrapper'>
                        <div className='grid grid-cols-1 gap-12'>

                            <div
                                className='bg-white w-[188px] h-[44px] drop-shadow-sm shadow-sm flex flex-row items-center text-center justify-center'>
                                <button className='font-semibold text-[19px] flex flex-row items-center'
                                    onClick={() => setToggleImagePicker(!toggleImagePicker)}><img src={PhotoIcon}
                                        alt="pen-icon"
                                        className='mr-5' /> Choose
                                    Photo
                                </button>

                            </div>

                            <div
                                className='bg-white w-[188px] h-[44px] drop-shadow-sm shadow-sm flex flex-row justify-center items-center text-center'>
                                <button className='font-semibold text-[19px] flex flex-row items-center'
                                    onClick={() => setToggleTextEdit(!toggleTextEdit)}><img src={PenIcon}
                                        alt="pen-icon"
                                        className='mr-5' /> Add
                                    Text
                                </button>
                            </div>

                        </div>
                        <div className='mt-12'>
                            <p className='my-4 font-semibold text-[18px]'>Choose Font</p>
                            <div className='flex flex-row space-x-3'>
                                {cover_fonts?.map((item, index) => {
                                    return (
                                        <button
                                            key={index}
                                            className={`bg-white w-[42px] h-[29px] drop-shadow-sm shadow-sm font-bold text-[19px] ${item.main_font === selectedFont ? selectedFont : item.main_font}  ${item.main_font === selectedFont || item.main_font === coverImage.main_font ? 'outline outline-[#ED4A43] outline-2' : ''}`}
                                            onClick={() => getSelectedFont(item.main_font, item.accompanying_font)}
                                        >
                                            A <span className={`font-light text-[10px] ${item.accompanying_font === selectedSecondFont ? selectedSecondFont : item.accompanying_font}`}>A</span>
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='choose-background-wrapper mt-6'>
                        <p className='my-4 font-semibold text-[18px]'>Background Color</p>

                        <div className='grid grid-cols-4 gap-3 justify-items-even'>
                            {initial_bg_colors?.map((item, index) => {
                                return (
                                    <button key={index}
                                        className={`w-[42px] h-[29px] ${item.color === selectedColor || item.color === coverImage.background_color ? 'outline outline-[#ED4A43] outline-2' : ''}`}
                                        style={{ background: item.color }}
                                        onClick={() => getBgColor(item.color)}></button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='choose-background-wrapper mt-6'>
                        <p className='my-4 font-semibold text-[18px]'>Text Color</p>

                        <div className='grid grid-cols-4 gap-3 justify-items-even'>
                            {initial_txt_colors?.map((item, index) => {
                                return (
                                    <button key={index}
                                        className={`w-[42px] h-[29px] ${item.color === selectedTextColor || item.color === coverImage.text_color ? 'outline outline-[#ED4A43] outline-2' : ''}`}
                                        style={{ background: item.color }}
                                        onClick={() => getTxtColor(item.color)}></button>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {coverImage &&
                    <div className='w-[550px] flex flex-row justify-center items-end'>
                        <div>
                            <button onClick={handlePrev}
                                className='outline-none cover-swipe-left-btn bg-[#C2C2C2] flex flex-row justify-center items-center w-[36px] h-[36px] rounded-full'>
                                <img src={leftArrow} />
                            </button>
                        </div>

                        <Swiper
                            navigation={false}
                            pagination={true}
                            allowTouchMove={false}
                            ref={sliderRef}
                            modules={[Navigation, Pagination]}
                            initialSlide={coverImage?.option - 1}
                            className="cover-swiper drop-shadow-xl"
                            speed={0.5}
                        >
                            <SwiperSlide>
                                <Option1 cover_crop={cover_crop} image_dimensions={imageDimensions} image={coverImage}
                                    bg_color={coverImage?.background_color} txt_color={coverImage?.text_color}
                                    main_font={coverImage?.main_font} second_font={coverImage?.second_font}
                                    id={1} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option2 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={2} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option3 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={3} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option4 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={4} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option5 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={5} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option6 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={6} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option8 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={7} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option9 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={8} />
                                </Suspense>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Option10 cover_crop={cover_crop} image_dimensions={imageDimensions}
                                        image={coverImage} bg_color={coverImage?.background_color}
                                        txt_color={coverImage?.text_color} main_font={coverImage?.main_font}
                                        second_font={coverImage?.second_font} id={9} />
                                </Suspense>
                            </SwiperSlide>
                        </Swiper>

                        <div>
                            <div>
                                <button onClick={handleNext}
                                    className='outline-none cover-swipe-right-btn bg-[#C2C2C2] flex flex-row justify-center items-center w-[36px] h-[36px] rounded-full'>
                                    <img src={rightArrow} />
                                </button>
                            </div>
                        </div>


                        {toggleTextEdit &&
                            <Change_Cover_Text_Modal
                                images={uploadedImages}
                                set_toggle={setToggleTextEdit}
                                session_id={session_id}
                            />}
                        {toggleImagePicker &&
                            <CoverImagePicker images={uploadedImages} set_toggle={setToggleImagePicker}
                                session_id={session_id} />}
                    </div>
                }

            </div>
            <div className='flex flex-row justify-end my-6 mx-12'>
                <button
                    className='bg-[#E94B45] w-[150px] h-[60px] text-[18px] font-bold text-white rounded-full hover:text-[#E94B45] hover:bg-white hover:transition-all hover:border-[#E94B45] hover:border'
                    onClick={() => updateCover()}>Update
                </button>
            </div>
        </>
    )
}

export default Cover