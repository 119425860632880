import React, { useEffect } from 'react'
import PageShadow from "../../../../assets/cover/shadow-p.png"
import CoverImageCropper from '../cropper-options/CoverImageCropper'

function Option1({ cover_crop, image_dimensions, image, bg_color, txt_color, main_font, second_font, id }) {

    return (
        <>
            <div className="bg-white overflow-hidden h-[475px]" style={{ backgroundColor: bg_color }}>
                <div className='relative top-0 left-0'>
                    <img alt='' src={PageShadow} className="h-[475px] relative top-0 left-[-10px] z-[2]" />
                    {image_dimensions.height < 1000 ?
                        <img alt='' src={image.src} className='h-[356px] w-full object-cover absolute top-0 left-0 z-[1]' />
                        :
                        <div className='h-[300px] w-full object-cover absolute top-0 left-0 z-[1]'>
                            <CoverImageCropper cover_crop={cover_crop} image={image} width={480} height={360} id={id} />
                        </div>
                    }
                    <div className='mb-6 absolute bottom-[-25px] left-[10px]'>
                        {image.title !== '' ?
                            <>
                                <p className={`text-black mx-6 uppercase text-[25px] font-bold ${main_font}`} style={{ color: txt_color }}>
                                    {image.title}
                                </p>
                                <p className={`text-black mx-6 text-[15px] font-light pb-6 ${second_font}`} style={{ color: txt_color }}>
                                    {image.subtitle}
                                </p>
                            </>
                            :
                            <p className='text-black mx-6 uppercase text-sm my-8 font-bold'>If you don't want this to stay blank please click <span className='text-primary'>Add Text</span> to add Title & Subtitle!</p>
                        }
                    </div>
                </div>
            </div>

            <p className='translate-y-6 text-center'>Cover Model 1</p>
        </>
    )
}

export default Option1