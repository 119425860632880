import React from 'react'
import { useLocation } from 'react-router-dom'

function Option7({ image, onLoad }) {
    const location = useLocation();

    if (location.pathname.includes("basket")) {
        return (
            <div className='flex flex-col justify-between w-[230px] p-3 h-full drop-shadow-lg scale-[0.8]'
                style={{
                    color: image.text_color,
                    backgroundColor: image.background_color
                }}
            >
                <img
                    src={image.cropped_src ? image.cropped_src : image.src}
                    className="object-contain w-full h-full mx-auto"
                    alt=''
                    onLoad={onLoad}
                />

                <p className='text-[10px] text-center font-bold mt-2'>{image.title}</p>
                <p className='text-[8px] text-center font-light'>{image.subtitle}</p>


            </div>
        )
    }

    return (

        <div className='flex flex-col h-full'
            style={{
                color: image.text_color,
                backgroundColor: image.background_color
            }}
        >
            <img
                src={image.cropped_src ? image.cropped_src : image.src}
                className="object-contain w-[80%] h-full mx-auto"
                alt=''
                onLoad={onLoad}
            />
            <p className='text-[5px] text-center'>{image.title}</p>
            <p className='text-[3px] text-center font-light pb-1'>{image.subtitle}</p>

        </div>
    )
}

export default Option7