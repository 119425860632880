import React, { useState } from 'react'
import AvatarEditor from 'react-avatar-editor'



function CoverImageCropper({ image, width, height, cover_crop, id }) {

  const [cooridnates, setCoordinates] = useState({
    x: 0,
    y: 0
  })

  const addToRefs = (el) => {
    if (el && !cover_crop.current.hasOwnProperty(id)) {
      cover_crop.current[id] = el;
    }

  }


  const handlePositionChange = (position) => {

    setCoordinates({
      x: position.x,
      y: position.y
    });

    image.x = position.x;
    image.y = position.y;
  }

  return (
    <AvatarEditor
      ref={addToRefs}
      image={image.src}
      width={width}
      height={height}
      border={0}
      crossOrigin={'anonymous'}
      onPositionChange={(position) => handlePositionChange(position)}
      position={{
        x: image.x,
        y: image.y
      }}
    />

  )
}

export default CoverImageCropper