import { db } from "../firebase";

import { set, ref, onValue, remove } from "firebase/database";

function getSessionId() {
    if (!sessionStorage.getItem('sessionId'))
        sessionStorage.setItem('sessionId', Math.floor(Math.random() * 999999999999).toString());
    return sessionStorage.getItem('sessionId');
}

function createSessionId() {
    const session = Math.floor(Math.random() * 999999999999).toString();
    sessionStorage.setItem('sessionId', session);
    return session
}


let getLastAlbumIndex = () => {
    return sessionStorage.getItem('lastAlbumIndex');
}

let setAlbumIndexSession = (id = 0) => {
    if (!id) {
        let last_index = getLastAlbumIndex();
        let index = 1;
        if (last_index)
            index = parseInt(last_index) + 1;

        sessionStorage.setItem('lastAlbumIndex', index.toString())
        sessionStorage.setItem('albumIndex', index.toString())
    } else {
        sessionStorage.setItem('albumIndex', id.toString())
    }
}

function getAlbumIndexSession() {
    if (!sessionStorage.getItem('albumIndex'))
        sessionStorage.setItem('albumIndex', '1')
    return sessionStorage.getItem('albumIndex');
}


function getFirebaseDataOfSession(length = false) {

    const sessionId = getSessionId();

    onValue(ref(db, sessionId), snap => {
        let images = snap.val();
        if (!images) {
            return null;
        }

        if (length) {
            return images[Object.keys(images)[0]].length;
        }

        return images[Object.keys(images)[0]];
    })

}

async function postImageFirebase(images, del = false, current_album_id, default_images) {
    let current_session = getFirebaseDataOfSession();

    if (!current_session) {
        current_session = {};
    }
    const sessionId = getSessionId();
    let album_index = getAlbumIndexSession();
    let album_id = current_album_id;

    if (images === []) {
        images = default_images;
    }
    if (del) {
        delete current_session[album_index];
    } else {
        current_session[album_index] = {};
        current_session[album_index] = {
            'images': images,
            'album_id': parseInt(album_id),
            'copies': 1
        }
    }

    // Remove from current session

    await remove(ref(db, sessionId));

    // Update Current Session

    await set(ref(db, sessionId), current_session);

}

function getImagesFromFirebase(default_images, setImages, setStateChanged) {

    // Maybe edit this later 

    const album = getAlbumIndexSession();

    const sessionId = getSessionId();

    onValue(ref(db, sessionId), snap => {
        const images = snap.val();

        if (!images || !images[Object.keys(images)[0]][album])
            return null


        setImages([...default_images, images[1].images])

        setStateChanged(true);

    })
}


export {
    getLastAlbumIndex,
    setAlbumIndexSession,
    getSessionId,
    createSessionId,
    getFirebaseDataOfSession,
    postImageFirebase,
    getImagesFromFirebase,
}