import React from 'react'
import LoadingSpinner from "../../../assets/loading.svg";


function Option1({ image, isLoaded, setIsLoaded }) {

  const default_style = {
    width: '60px',
    height: '60px',
  }

  const fill_style = {
    width: '100%',
    height: '100%',
  }



  return (
    <>
      <img
        src={image.cropped_src ? image.cropped_src : image.src}
        className="object-contain"
        style={image.fill === true ? fill_style : default_style}
        alt=''
        onLoad={() => setIsLoaded(!isLoaded)}
      />
    </>

  )
}

export default Option1