import React from 'react'
import { useNavigate } from 'react-router-dom'
import ProgressModal from '../common/ProgressModal';

function EmptyBasket({ loadingStatus }) {
    const location = useNavigate();

    function navigateToShop() {
        location('/')
    }
    
    return (
        <div className="w-full h-[80vh] flex justify-center items-center text-center">
            {loadingStatus !== undefined && <ProgressModal uploading={loadingStatus} />}

            <div className='grid grid-rows-3 justify-items-center items-center mx-auto'>
                <p className="text-[28px] xl:text-[35px] lg:text-[33px] md:text-[30px] sm:text-[28px] font-bold">Your basket is empty!</p>
                <div className="text-center mx-auto pb-[100px]">
                    <button
                        onClick={() => navigateToShop()}
                        className="w-[110px] xl:w-[200px] lg:w-[170px] md:w-[150px] sm:w-[140px] text-[11px] xl:text-[16px] lg:text-[16px] md:text-[15px] sm:text-[14px] text-white h-[40px] xl:h-[50px] lg:h-[50px] md:h-[50px] sm:h-[50px]  bg-[#BBB9B9] rounded-full font-bold hover:transition-all hover:opacity-90"
                    >
                        Back to shopping
                    </button>
                </div>
            </div>

        </div>
    )
}

export default EmptyBasket