import React, { useState } from 'react';
import EmptyIcon from "../../../assets/svgs/img-icon.svg";
import "../styles.css";
import EditPen from "../../../assets/svgs/edit.svg";
import TrashIcon from "../../../assets/svgs/Trash.svg";
import LoadingSpinner from "../../../assets/loading.svg";

import { useNavigate } from 'react-router-dom';

import Option4 from '../previews/Option4';
import Option1 from '../previews/Option1';
import Option2 from '../previews/Option2';
import Option3 from '../previews/Option3';
import Option5 from '../previews/Option5';
import Option6 from '../previews/Option6';
import Option7 from '../previews/Option7';
import Option8 from '../previews/Option8';
import Option9 from '../previews/Option9';
import Option10 from '../previews/Option10';

function Image({ item, index, deleteItem, customClass }) {

    const history = useNavigate();
    const editItem = (index) => {
        sessionStorage.setItem('selected_image_index', String(index));
        if (index === 5) {
            history(`/album/edit/${index - 1}-${index}`);
        } else {
            history(`/album/edit/${index}-${index + 1}`)
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);


    const editCover = () => {
        history('/album/cover')
    }


    return (
        <>
            {
                item?.src === 'cover' &&
                <>
                    <div className={'box ' + customClass}>
                    </div>
                    <p className='text-center text-[13px] mt-1 text-[#595959]'>{index === 1 && "Cover"}</p>
                </>
            }
            {
                item?.src === 'empty' &&
                <div className={`box  ${customClass} ${index > 0 && index % 2 == 0 ? "left-box" : 'right-box'}`}>
                    <span className="tooltiptext" style={{ backgroundColor: '#E13234' }}>
                        <div className="text">Qëndron e bardhë, nuk mund te printohet.</div>
                    </span>

                </div>
            }
            {item?.src === 'delete' ? <div style={{ width: "50px", display: 'none' }}></div> : null}

            {
                item?.src === 'icon' &&
                <>
                    <div className={`box ${index > 0 && index % 2 == 0 ? "left-box" : 'right-box'}`}>
                        <span className="tooltiptext">
                            <div className="text">Albumi fotografik përmban minimalisht 36 faqe.
                                Nëse nuk dëshironi që këto faqe të mbeten të bardha,
                                ju mund të ngarkoni imazhe.</div>
                        </span>

                        <img src={EmptyIcon} className='w-[25px] h-[23px]' alt='' />
                    </div>
                    <p className='text-center text-[13px] mt-1 text-[#595959]'>{index - 4}</p>
                </>
            }
            {
                item?.src === 'back' &&
                <>
                    <div className={`box ${customClass}`}>
                        <span className="tooltiptext" style={{ backgroundColor: '#E13234' }}>
                            <div className="text">Kopertina e pasme nuk mund të modifikohet.
                                Merr ngjyrën e sfondit të zgjedhur në kopertinën e parme.</div>
                        </span>

                    </div>
                    <p className='text-center text-[13px] mt-1 text-[#595959]'>Back Cover</p>
                </>
            }
            {
                item?.src !== 'icon' && item?.src !== 'cover' && item?.src !== 'empty' && item?.src !== 'delete' && item?.src !== 'back' ?
                    <>
                        <div className={`box ${index % 2 == 0 ? "left-box" : `${index > 2 ? 'right-box' : ''}`}`}>
                            {index === 1 ?
                                <span className="tooltiptext tooltiptext-cover">
                                    <div className="left-tooltip edit-cover hover:cursor-pointer" >
                                        <img src={EditPen} className="fill-white" alt="" onClick={() => editCover(item.src)} />
                                    </div>
                                </span> :

                                <span className="tooltiptext">
                                    <div className="left-tooltip mx-auto hover:cursor-pointer">
                                        <img src={EditPen} className="fill-white" alt="" onClick={() => editItem(index)} />
                                    </div>
                                    <div className="right-tooltip mx-auto hover:cursor-pointer">
                                        <img src={TrashIcon} className="fill-white" alt="" onClick={() => deleteItem(index)} />
                                    </div>
                                </span>
                            }
                            {!isLoaded && <img src={LoadingSpinner} className='w-[30px] absolute z-[1000]' />}

                            {item.padding_option === 0 || item.option === 0 ? <Option1 image={item} setIsLoaded={setIsLoaded} /> : null}
                            {item.padding_option === 1 || item.option === 1 ? <Option2 image={item} index={index} onLoad={() => setIsLoaded(!isLoaded)} /> : null}
                            {item.padding_option === 2 || item.option === 2 ? <Option3 image={item} index={index} onLoad={() => setIsLoaded(!isLoaded)} /> : null}
                            {item.padding_option === 3 || item.option === 3 ? <Option4 image={item} index={index} onLoad={() => setIsLoaded(!isLoaded)} /> : null}
                            {item.option === 4 && <Option5 image={item} onLoad={() => setIsLoaded(!isLoaded)} />}
                            {item.option === 5 && <Option6 image={item} onLoad={() => setIsLoaded(!isLoaded)} />}
                            {item.option === 6 && <Option7 image={item} onLoad={() => setIsLoaded(!isLoaded)} />}
                            {item.option === 7 && <Option8 image={item} onLoad={() => setIsLoaded(!isLoaded)} />}
                            {item.option === 8 && <Option9 image={item} onLoad={() => setIsLoaded(!isLoaded)} />}
                            {item.option === 9 && <Option10 image={item} onLoad={() => setIsLoaded(!isLoaded)} />}
                        </div>
                        {item?.src === 'cover' || index === 1 ?

                            <p className='text-center text-[13px] mt-1 text-[#595959]'>Cover</p>
                            : null
                        }
                        {item?.src !== 'cover' && item?.src !== 'empty' && item?.src !== 'delete' && item?.src !== 'back' && index !== 1 ?
                            <>
                                <p className='text-center text-[13px] mt-1 text-[#595959]'>{index - 4}</p>
                            </> : null}
                    </>
                    : null
            }
        </>
    )
}

export default Image
