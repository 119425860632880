import React from 'react'

function Help() {
  return (
    <div>
      <h1 className='font-bold'>1 photo = 1 page</h1>
      For example: If you upload 80 images, you will have a book of 80 pages (40 sheets).<br />
      <br />
      Allowed type of images is  <span className='font-bold'>JPG, JPEG & PNG</span>, <br />
      Maximal number of pictures is <span className='font-bold'>250</span>.<br />
      Images must be in <span className='font-bold'>RGB</span> mode.<br />
      Maximal file size is <span className='font-bold'>20MB</span>.<br />
      Minimal image size is <span className='font-bold'>400 x 400 px</span>.<br />
    </div>
  )
}

export default Help