import React from 'react'
import { useLocation } from 'react-router-dom'

function Option5({ image, onLoad }) {

    const location = useLocation();

    if (location.pathname.includes("basket")) {
        return (
            <div className='flex flex-col justify-between w-[230px] h-full drop-shadow-lg scale-[0.8]'
                style={{
                    color: image.text_color,
                    backgroundColor: image.background_color
                }}
            >
                <img
                    src={image.cropped_src ? image.cropped_src : image.src}
                    className="object-contain w-full h-full mx-auto"
                    alt=''
                    onLoad={onLoad}
                />
                <div className='absolute bottom-4 left-0 right-0'>
                    <p className='text-[10px] text-center'>{image.title}</p>
                    <p className='text-[8px] text-center font-light'>{image.subtitle}</p>
                </div>

            </div>
        )
    }

    return (

        <div className='flex flex-col'
            style={{
                color: image.text_color,
                backgroundColor: image.background_color
            }}
        >

            <img
                src={image.cropped_src ? image.cropped_src : image.src}
                className="object-contain w-full h-full mx-auto"
                alt=''
                onLoad={onLoad}
            />
            <div className='absolute bottom-2 left-0 right-0'>
                <p className='text-[5px] text-center'>{image.title}</p>
                <p className='text-[3px] text-center font-light'>{image.subtitle}</p>
            </div>

        </div>
    )
}

export default Option5