import React from 'react'

function NotAllowed() {
    return (
        <div className='w-2/3 mx-auto h-[50vh] flex flex-col justify-center items-center'>
            <p className='text-[20px] text-center'>
                Unfortunately this application does not fit on this type of screen dimensions.
                <br />
                <span className='font-semibold'>Please use another device which has a bigger screen !</span>
            </p>
        </div>
    )
}

export default NotAllowed